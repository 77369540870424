import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import DatePicker from 'react-datepicker'
import {useTranslation} from 'react-i18next'
import {ButtonElement} from './index'
import {ChevronDownIcon, ChevronUpDownIcon} from '../icons'
import {getCurrentNetwork} from '../../store/appSlice'

interface propsType {
    onChange: (value: Date | null) => void
    value: Date | null
}

const DateFilter = (props: propsType) => {
    const {t} = useTranslation()
    const [opened, setOpened] = useState(false)
    const currentNetwork = useSelector(getCurrentNetwork)

    const closeMenu = () => {
        const body = document.querySelector('body')
        if (body) {
            body.onclick = null
        }
        setOpened(false)
    }
    const openMenu = () => {
        if (opened) {
            return
        }

        setTimeout(() => {
            const body = document.querySelector('body')
            if (body) {
                body.onclick = closeMenu
            }
        }, 100)
        setOpened(true)
    }

    useEffect(() => {
        closeMenu()
    }, [currentNetwork])

    return <DatePicker
        selected={props.value}
        onChange={props.onChange}
        customInput={<div className="btn-group w-100">
            <div className="btn-group flex-grow-1 dropdown">
                <ButtonElement
                    small
                    outline
                    additionalClass={'btn-filter-select dropdown-toggle'}
                    onClick={openMenu}
                >
                    <span className="me-2">{props.value?.toDateString() || t('element.anyDate')}</span>
                    <ChevronDownIcon/>
                </ButtonElement>
            </div>
            <ButtonElement small outline additionalClass={'btn-sort-arrows flex-grow-0'}><ChevronUpDownIcon/></ButtonElement>
        </div>}
    />
}

export default DateFilter
