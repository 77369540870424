import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import Tippy from '@tippyjs/react'
import {useTranslation} from 'react-i18next'
import {delTicketFromWhitelist, getTicketLevelId, sendTicketToWhitelist} from '../../store/ticketsSlice'
import {AppDispatch} from '../../store/store'
import {ButtonElement, CheckboxElement, CopyToClipboardBlock} from '../elements'
import {getCurrentNetwork, sendRequestWithAuth, setModalSendToken} from '../../store/appSlice'
import {getEvent, getSelectedEventId, requestEvent} from '../../store/eventsSlice'
import {compactString, getDateString} from '../../utils/functions'
import {CHAINS} from '../../utils/constants'
import {ITicket} from '../../store/types'
import {ThemeContext} from '../../context'
import {CrossIcon, EmptyIcon, ExternalLinkSmallIcon, TicketIcon} from '../icons'
import {TicketDescription} from './index'
import {getToken, requestToken} from '../../store/tokensSlice'

interface PropsType {
    item: ITicket
}

const TicketItem = ({item}: PropsType) => {
    const {t} = useTranslation()
    const {dark} = useContext(ThemeContext)
    const [opened, setOpened] = useState(false)
    const currentNetwork = useSelector(getCurrentNetwork)
    const selectedEventId = useSelector(getSelectedEventId)
    const ticketLevelId = useSelector(getTicketLevelId)
    const event = useSelector(getEvent(item.eventId))
    const token = useSelector(getToken(item.contract, item.tokenId))
    const networkStr = `0x${Number(item.network).toString(16)}`
    let date = token && token.date ? getDateString(token.date) : item.blockNum.toString()

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (!event && item.isTicket) {
            dispatch(requestEvent(item.eventId))
        }
        dispatch(requestToken({address: item.contract, tokenId: item.tokenId, network: networkStr}))
    }, [])

    const onChangeHandler = () => {
        if (ticketLevelId === null || item.isUsed) {
            return
        }

        if (item.level && item.level.id === ticketLevelId) {
            dispatch(sendRequestWithAuth(delTicketFromWhitelist({
                chain: Number(currentNetwork),
                contract: item.contract,
                tokenId: item.tokenId,
                levelId: item.level.id,
                assetType: item.assetType,
            })))
        } else {
            dispatch(sendRequestWithAuth(sendTicketToWhitelist({
                chain: Number(currentNetwork),
                contract: item.contract,
                tokenId: item.tokenId,
                levelId: ticketLevelId,
                assetType: item.assetType,
            })))
        }
    }
    const sendTokenHandler = () => {
        dispatch(setModalSendToken({
            tokenId: item.tokenId,
            contract: item.contract,
            assetType: item.assetType
        }))
    }

    return <div className="row-table__item mb-4 mb-xl-2">
        <div className="row align-items-center gx-2 gx-md-4">
            <div className="mb-2 col-2 col-md-auto order-1 order-md-0">
                {ticketLevelId !== null ?
                    item.isTicket && item.eventId === selectedEventId && item.level.id !== ticketLevelId ?
                        <Tippy
                            content={t('status.whitelistedTicket')}
                            appendTo={document.getElementsByClassName("wrapper")[0]}
                            trigger='mouseenter'
                            interactive={false}
                            arrow={false}
                            maxWidth={512}
                        >
                            <span>
                                <CheckboxElement
                                    checked={item.isTicket}
                                    onChange={onChangeHandler}
                                    disabled={true}
                                />
                            </span>
                        </Tippy>
                        :
                        item.eventId === selectedEventId && item.isUsed ?
                            <Tippy
                                content={t('status.usedTicket')}
                                appendTo={document.getElementsByClassName("wrapper")[0]}
                                trigger='mouseenter'
                                interactive={false}
                                arrow={false}
                                maxWidth={512}
                            >
                                <span className="icon-gray mt-1"><CrossIcon/></span>
                            </Tippy>
                            :
                            <CheckboxElement
                                checked={item.eventId === selectedEventId && item.isTicket}
                                onChange={onChangeHandler}
                            />
                    :
                    <span className="icon-empty mt-1"><EmptyIcon/></span>
                }
            </div>
            <div className="mb-2 col-5 col-md-auto order-2 order-md-0">
                <span className="d-md-none text-muted me-2">{t('form.label.chain')}:</span>
                <img
                    src={`/img/nets/${dark ? 'dark' : 'light'}-mode/${CHAINS[`0x${Number(item.network).toString(16)}`]?.iconSvg}`}
                    alt={CHAINS[`0x${Number(item.network).toString(16)}`]?.label}
                />
            </div>
            <div className="mb-2 col-5 col-md-auto order-4 order-md-0 offset-2 offset-md-0">
                <span className="d-md-none text-muted me-2">
                    {item.isTicket ? `${t('form.label.type')}:` : t('form.label.notTicket')}
                </span>
                <span className={`icon-${item.isTicket ? (item.isUsed ? 'ticket' : 'ticket-active') : 'empty'}`}>
                    <TicketIcon/>
                </span>
            </div>
            <div className="mb-2 col-5 col-md order-3 order-md-0 col-md-max-140">
                <span>{compactString(item.contract)}</span>
                <CopyToClipboardBlock text={item.contract}/>
            </div>
            <div className="mb-2 col-5 col-md col-xl-2 order-5 order-md-0">
                <span>{compactString(item.tokenId.toString())}</span>
                <CopyToClipboardBlock text={item.tokenId.toString()}/>
            </div>
            <div className="d-none d-xl-block mb-2 col-xl-2 text-truncate">
                {event ? <Link to={`/event/${event.organizerUrl}/${event.url}`}>{event.title}</Link> : null}
            </div>
            <div className="d-none d-xl-block mb-2 col-xl">
                <span>{item.level ? `${item.level.title} (${item.level.level})` : ''}</span>
            </div>
            <div className="d-none d-md-block mb-2 col-auto">
                {item.tokenUri ?
                    <a href={item.tokenUri} target={'_blank'} rel={'noreferrer'} className={'icon-link'}>JSON</a>
                    :
                    null
                }
            </div>
            <div className="d-none d-md-block mb-2 col-auto">
                <a
                    className="icon-link"
                    href={CHAINS[networkStr].blockExplorerNftLink(item.contract, item.tokenId.toString())}
                    target={'_blank'}
                    rel={'noreferrer'}
                >
                    <span className="text-white">{date}</span>
                    <ExternalLinkSmallIcon/>
                </a>
            </div>
            <div className="d-none d-lg-block mb-2 col-auto">
                <ButtonElement
                    small
                    outline
                    onClick={sendTokenHandler}
                >{t('button.send')}</ButtonElement>
            </div>
            <div className="d-none d-lg-block mb-2 col-auto">
                <button className="btn p-2 btn-with-opacity btn-toggle" onClick={() => {
                    setOpened(!opened)
                }}>
                    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M8.7 11.5a1 1 0 0 1-1.4 0L2.5 6.7a1 1 0 1 1 1.4-1.4L8 9.38l4.1-4.1a1 1 0 1 1 1.4 1.42l-4.8 4.8Z"
                              fill="#EEE"></path>
                    </svg>
                </button>
            </div>
        </div>
        <div className="row-table__item-details border-top mt-1" style={{display: opened ? 'block' : 'none'}}>
            <TicketDescription item={item} event={event}/>
        </div>
        <div className="row-table__item-toggle d-lg-none">
            <div className="btn-group w-100">
                <ButtonElement
                    small
                    type={'no-primary'}
                    onClick={() => {
                        setOpened(!opened)
                    }}
                >{opened ? t('button.hideDetails') : t('button.showDetails')}</ButtonElement>
                <ButtonElement
                    small
                    onClick={sendTokenHandler}
                >{t('button.send')}</ButtonElement>
            </div>
        </div>
    </div>
}

export default TicketItem
