import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {Link, useMatch, useMatches} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {WalletBlock} from '../wallet'
import {ToggleDarkMode} from '../elements'
import {ENVIRONMENT, MAIN_MENUS, SUB_MENU} from '../../utils/constants'
import {getCurrentEventId, getEvent} from '../../store/eventsSlice'
import {getWalletAddress} from '../../store/appSlice'
import {MyShChIcon} from '../icons'

const Header = () => {
    const {t} = useTranslation()
    const urlMatch = useMatch('/event/:organizerUrl/:eventUrl')
    const matches = useMatches()
    const [opened, setOpened] = useState(false)
    const currentEventId = useSelector(getCurrentEventId)
    const event = useSelector(getEvent(currentEventId))
    const walletAddress = useSelector(getWalletAddress)
    let activeMenu = -1
    for (let match of matches) {
        if ((match.handle as any)?.mainMenu !== undefined) {
            activeMenu = (match.handle as any).mainMenu
            break
        }
    }
    let activeSubMenu = -1
    for (let match of matches) {
        if ((match.handle as any)?.subMenu !== undefined) {
            activeSubMenu = (match.handle as any).subMenu
            break
        }
    }

    const closeMenu = () => {
        setOpened(false)
    }

    return <header className="s-header">
        <div className="container">
            <div className="d-flex align-items-center h-100">
                <Link to={'/'} className="s-header__logo">
                    <MyShChIcon/>
                    {ENVIRONMENT !== 'prod' ?
                        <span className="app-status">{ENVIRONMENT}</span>
                        :
                        null
                    }
                </Link>
                {!urlMatch || (event && event.organizer === walletAddress) ?
                    <>
                        <button
                            className={`s-header__nav-toggle ${opened ? 'opened' : ''}`}
                            onClick={() => setOpened(!opened)}
                        >
                            <span className="burger-lines"></span>
                        </button>
                        <div className="mob-nav-bg"></div>
                        <div className="dropdown-menu s-header__nav" onClick={closeMenu}>
                            <div className="nav-item">
                                <div className={`link ${activeMenu === MAIN_MENUS.classic ? 'active' : ''}`}>
                                    <span className="me-2">{t('menu.classic')}</span>
                                    <svg className="icon-sm icon-base-white" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M12.7 15.1a1 1 0 0 1-1.4 0l-4.8-4.8a1 1 0 1 1 1.4-1.4l4.1 4.09 4.1-4.1a1 1 0 1 1 1.4 1.42l-4.8 4.8Z"></path>
                                    </svg>
                                </div>
                                <div className="nav-dropdown">
                                    <div className="nav-item">
                                        <Link
                                            to="/classic/mint"
                                            className={`link ${activeSubMenu === SUB_MENU.classicMint ? 'active' : ''}`}
                                        >{t('menu.mint')}</Link>
                                    </div>
                                    <div className="nav-item">
                                        <Link
                                            to="/classic/showcases"
                                            className={`link ${activeSubMenu === SUB_MENU.classicShowcases ? 'active' : ''}`}
                                        >{t('menu.showcase')}</Link>
                                    </div>
                                    <div className="nav-item">
                                        <Link
                                            to="/classic/events"
                                            className={`link ${activeSubMenu === SUB_MENU.classicEvents ? 'active' : ''}`}
                                        >{t('menu.events')}</Link>
                                    </div>
                                    <div className="nav-item">
                                        <Link to="/sbt" className="link">{t('menu.certificates')}</Link>
                                    </div>
                                    <div className="nav-item">
                                        <Link
                                            to="/classic/stats/events"
                                            className={`link ${activeSubMenu === SUB_MENU.classicStat ? 'active' : ''}`}
                                        >{t('menu.stats')}</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="nav-item">
                                <div className={`link ${activeMenu === MAIN_MENUS.lazy ? 'active' : ''}`}>
                                    <span className="me-2">{t('menu.lazy')}</span>
                                    <svg className="icon-sm icon-base-white" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M12.7 15.1a1 1 0 0 1-1.4 0l-4.8-4.8a1 1 0 1 1 1.4-1.4l4.1 4.09 4.1-4.1a1 1 0 1 1 1.4 1.42l-4.8 4.8Z"></path>
                                    </svg>
                                </div>
                                <div className="nav-dropdown">
                                    <div className="nav-item">
                                        <Link
                                            to="/lazy/mint"
                                            className={`link ${activeSubMenu === SUB_MENU.lazyMint ? 'active' : ''}`}
                                        >{t('menu.tariff')}</Link>
                                    </div>
                                    <div className="nav-item">
                                        <Link
                                            to="/lazy/showcases"
                                            className={`link ${activeSubMenu === SUB_MENU.lazyShowcases ? 'active' : ''}`}
                                        >{t('menu.lazyShowcase')}</Link>
                                    </div>
                                    <div className="nav-item">
                                        <Link
                                            to="/lazy/events"
                                            className={`link ${activeSubMenu === SUB_MENU.lazyEvents ? 'active' : ''}`}
                                        >{t('menu.events')}</Link>
                                    </div>
                                    <div className="nav-item">
                                        <Link to="/sbt" className="link">{t('menu.certificates')}</Link>
                                    </div>
                                    <div className="nav-item">
                                        <Link
                                            to="/lazy/stats/events"
                                            className={`link ${activeSubMenu === SUB_MENU.lazyStat ? 'active' : ''}`}
                                        >{t('menu.stats')}</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="nav-item">
                                <div className={`link ${activeMenu === MAIN_MENUS.onchain ? 'active' : ''}`}>
                                    <span className="me-2">{t('menu.onchain')}</span>
                                    <svg className="icon-sm icon-base-white" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M12.7 15.1a1 1 0 0 1-1.4 0l-4.8-4.8a1 1 0 1 1 1.4-1.4l4.1 4.09 4.1-4.1a1 1 0 1 1 1.4 1.42l-4.8 4.8Z"></path>
                                    </svg>
                                </div>
                                <div className="nav-dropdown">
                                    <div className="nav-item">
                                        <Link
                                            to="/onchain/events"
                                            className={`link ${activeSubMenu === SUB_MENU.onchainEvents ? 'active' : ''}`}
                                        >{t('menu.management')}</Link>
                                    </div>
                                    <div className="nav-item">
                                        <Link
                                            to="/onchain/stats"
                                            className={`link ${activeSubMenu === SUB_MENU.onchainStat ? 'active' : ''}`}
                                        >{t('menu.stats')}</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="nav-item">
                                <Link to="/gifts" className={`link ${activeMenu === MAIN_MENUS.gifts ? 'active' : ''}`}>
                                    {t('menu.gifts')}
                                </Link>
                            </div>
                            <div className="nav-item">
                                <Link
                                    to="/sbt"
                                    className={`link ${activeMenu === MAIN_MENUS.certificates ? 'active' : ''}`}
                                >
                                    {t('menu.certificates')}
                                </Link>
                            </div>
                        </div>
                    </>
                    :
                    null
                }
            </div>
            <div className="d-flex align-items-center">
                <ToggleDarkMode/>
                <WalletBlock/>
            </div>
        </div>
    </header>
}

export default Header
