import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ethers} from 'ethers'
import {Trans, useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {SelectEvent, SelectOrganizer, SelectTicketLevel} from '../profile'
import {getSelectedEventId} from '../../store/eventsSlice'
import {
    getTicketLevelId,
    getWhitelist,
    requestTicketsByEvent,
    sendTicketToWhitelist,
    setWhitelist
} from '../../store/ticketsSlice'
import {sendRequestWithAuth} from '../../store/appSlice'
import {WhitelistItem} from './index'
import {ButtonElement, DropdownSelector, InputElement, LabelElement} from '../elements'
import {_AssetType, CHAINS} from '../../utils/constants'
import {getChainsForDropdown} from '../../utils/functions'
import {ChevronDownIcon} from '../icons'

const Whitelists = () => {
    const {t} = useTranslation()
    const [addTicketsOpened, setAddTicketsOpened] = useState(false)
    const [selectedNetwork, setSelectedNetwork] = useState('')
    const [selectedNetworkError, setSelectedNetworkError] = useState('')
    const [ticketContract, setTicketContract] = useState('')
    const [ticketContractError, setTicketContractError] = useState('')
    const [tokenId, setTokenId] = useState('')
    const [tokenIdError, setTokenIdError] = useState('')
    const selectedEvent = useSelector(getSelectedEventId)
    const ticketLevelId = useSelector(getTicketLevelId)
    const whitelist = useSelector(getWhitelist)
    const networksList = getChainsForDropdown(true)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (selectedEvent) {
            dispatch(sendRequestWithAuth(requestTicketsByEvent()))
            return () => {
                dispatch(setWhitelist(null))
            }
        }
    }, [selectedEvent])

    const ticketsList = useMemo(() => {
        if (!selectedEvent) {
            return <div>{t('status.selectEvent')}</div>
        }

        if (!whitelist) {
            return <div>{t('status.loadingTicketsDescription')}</div>
        }

        if (whitelist.length === 0) {
            return <div>{t('status.emptyList')}</div>
        }

        return whitelist.map((item) => (<WhitelistItem key={`${item.id}`} item={item}/>))
    }, [whitelist])

    const addHandler = () => {
        let error = false
        let network: number = Number(selectedNetwork)
        let id = parseInt(tokenId)
        if (ticketContract === '' || !ethers.utils.isAddress(ticketContract)) {
            error = true
            setTicketContractError(t('error.pasteRightAddress'))
        } else {
            setTicketContractError('')
        }
        if (isNaN(id) || id < -1 || id.toString() !== tokenId) {
            error = true
            setTokenIdError(t('error.pasteRightTokenId'))
        } else {
            setTokenIdError('')
        }
        if (isNaN(network) || !CHAINS[selectedNetwork]) {
            error = true
            setSelectedNetworkError(t('error.selectRightNetwork'))
        } else {
            setSelectedNetworkError('')
        }
        if (error || !ticketLevelId) {
            return
        }

        dispatch(sendRequestWithAuth(sendTicketToWhitelist({
            chain: network,
            contract: ticketContract,
            tokenId,
            assetType: _AssetType.ERC721,
            levelId: ticketLevelId,
        })))
    }

    return <div className="page-content">
        <div className="container">
            <h1>{t('header.whitelists')}</h1>
            <div className="mb-3">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="mb-4">
                            <SelectOrganizer/>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="mb-4">
                            <SelectEvent/>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="mb-4">
                            <SelectTicketLevel/>
                        </div>
                    </div>
                </div>
                <ButtonElement
                    small
                    type={'secondary'}
                    additionalClass={`${addTicketsOpened ? 'collapse-opened' : ''} mb-2`}
                    onClick={() => {setAddTicketsOpened(!addTicketsOpened)}}
                >
                    <span className="me-2">{t('button.addTickets')}</span>
                    <ChevronDownIcon/>
                </ButtonElement>
                <div className={`collapse ${addTicketsOpened ? 'show' : ''}`}>
                    <div className="collapse-content">
                        <div className="row gx-3">
                            <div className="col-lg-3">
                                <div className="mb-4 mb-lg-0">
                                    <DropdownSelector
                                        list={networksList}
                                        setItem={setSelectedNetwork}
                                        currentItem={selectedNetwork}
                                        errorText={selectedNetworkError}
                                        label={`${t('form.label.network')} *`}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="mb-4 mb-lg-0">
                                    <InputElement
                                        errorText={ticketContractError}
                                        label={`${t('form.label.contract')} *`}
                                        value={ticketContract}
                                        onChange={setTicketContract}
                                        isAddress={true}
                                        setError={setTicketContractError}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="mb-4 mb-lg-0">
                                    <InputElement
                                        errorText={tokenIdError}
                                        label={`${t('form.label.tokenId')} *`}
                                        value={tokenId}
                                        onChange={setTokenId}
                                        setError={setTokenIdError}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="mb-4 mb-lg-0">
                                    <LabelElement additionalClass={'d-none d-lg-block'}>&nbsp;</LabelElement>
                                    <ButtonElement
                                        additionalClass={'w-100'}
                                        onClick={addHandler}
                                        disabled={ticketLevelId === null}
                                    >
                                        <Trans
                                            i18nKey={'button.addToWhitelist'}
                                            components={[<span className="d-lg-none d-xl-inline"/>]}
                                        />
                                    </ButtonElement>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="row-table">
                    {ticketsList}
                </div>
            </div>
        </div>
    </div>
}

export default Whitelists
