import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {CopyToClipboardBlock} from '../elements'
import {IWnft} from '../../store/types'
import {CHAINS} from '../../utils/constants'
import {ThemeContext} from '../../context'
import {ExternalLinkSmallIcon} from '../icons'
import {compactString} from '../../utils/functions'
import {getToken, requestToken} from '../../store/tokensSlice'
import {AppDispatch} from '../../store/store'

interface PropsType {
    item: IWnft
}

const SbtRow = ({item}: PropsType) => {
    const {t} = useTranslation()
    const {dark} = useContext(ThemeContext)
    const [detailsOpened, setDetailsOpened] = useState(false)
    const token = useSelector(getToken(item.contract, item.tokenId))
    const networkStr = `0x${Number(item.network).toString(16)}`

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(requestToken({address: item.contract, tokenId: item.tokenId, network: networkStr}))
    }, [])

    return <div className="row-table__item mb-4 mb-xl-2">
        <div className="row align-items-center gx-2 gx-md-4">
            <div className="mb-2 col-3 col-md-auto">
                {token ?
                    <div className="row-table__item-preview">
                        <img src={token.image} alt=""/>
                    </div>
                    :
                    null
                }
            </div>
            <div className="mb-2 col-4 col-md-auto">
                <span className="d-md-none text-muted me-2">{t('form.label.chain')}:</span>
                <img
                    src={`/img/nets/${dark ? 'dark' : 'light'}-mode/${CHAINS[networkStr]?.iconSvg}`}
                    alt={CHAINS[networkStr]?.label}
                />
            </div>
            <div className="mb-2 col-5 col-md-3">
                <span>ID {item.tokenId.toString()}</span>
                <CopyToClipboardBlock text={item.tokenId.toString()}/>
            </div>
            <div className="mb-2 col-9 col-md d-none d-md-block">
                <span className="text-muted me-2">{t('form.label.owner')}</span>
                <span>{compactString(item.owner)}</span>
                <CopyToClipboardBlock text={item.owner}/>
            </div>
            <div className="d-none d-lg-block mb-2 col-auto">
                {item.tokenUri ?
                    <a href={item.tokenUri} target={'_blank'} rel={'noreferrer'} className={'icon-link'}>JSON</a>
                    :
                    null
                }
            </div>
            <div className="d-none d-lg-block mb-2 col-auto">
                <a className="icon-link" href="#">
                    <span className="text-white">12 oct 2023 11:00</span>
                    <ExternalLinkSmallIcon/>
                </a>
            </div>
        </div>
        <div
            className="row-table__item-details border-top mt-1 d-lg-none"
            style={{display: detailsOpened ? 'block' : 'none'}}
        >
            <div className="row pt-3 pb-2">
                <div className="col-12 d-md-none">
                    <div className="row mb-2 gx-3 align-items-center">
                        <div className="col-auto">
                            <div className="text-muted text-lg-end">{t('form.label.owner')}</div>
                        </div>
                        <div className="col">
                            <span>{compactString(item.owner)}</span>
                            <CopyToClipboardBlock text={item.owner}/>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="d-flex align-items-center">
                        {item.tokenUri ?
                            <a href={item.tokenUri} target={'_blank'} rel={'noreferrer'} className={'icon-link'}>
                                JSON
                            </a>
                            :
                            null
                        }
                        <a className="icon-link" href="#">
                            <span className="text-white">12 oct 2023 11:00</span>
                            <ExternalLinkSmallIcon/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className="row-table__item-toggle d-lg-none">
            <button className="btn btn-sm" onClick={() => {
                setDetailsOpened(!detailsOpened)
            }}>
                {detailsOpened ? t('button.hideDetails') : t('button.showDetails')}
            </button>
        </div>
    </div>
}

export default SbtRow
