import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import {ethers} from 'ethers'
import {AbiItem} from 'web3-utils'
import i18next from 'i18next'
import {RootState} from './store'
import {IAssetItem, ICollection, ISendTransaction, IWnft, SliceResponse} from './types'
import {_AssetType, API_URL, CHAINS, SBT_FACTORY_BASE_URI} from '../utils/constants'
import {
    checkResponse, setModalCreateSbtCollection,
    setModalError,
    setModalMintSbt,
    setModalSendTransactions
} from './appSlice'
import {requestTokens} from './ticketsSlice'
import Erc721Abi from '../utils/abi/erc721.json'

interface SbtState {
    collections: ICollection[] | null
    sbtCollection: IWnft[] | null
}

const initialState: SbtState = {
    collections: null,
    sbtCollection: null,
}

export const createSbtCollection = createAsyncThunk(
    'sbt/createSbtCollection',
    async ({name, symbol}: { name: string, symbol: string }, {dispatch, getState}): Promise<boolean> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app

        if (!currentNetwork || !web3 || !walletAddress) {
            return false
        }

        let transactions: ISendTransaction[] = []
        const contract = new web3.eth.Contract(CHAINS[currentNetwork].eventsManagerContractAbi, CHAINS[currentNetwork].eventsManagerContract)
        const method = contract.methods.deployNewCollection(
            CHAINS[currentNetwork].sbtImpl721Contract,
            walletAddress,
            name,
            symbol,
            SBT_FACTORY_BASE_URI,
            CHAINS[currentNetwork].wrapperBatchContract,
        )
        const encodedABI = method.encodeABI()
        transactions.push({
            trx: {
                from: walletAddress,
                to: CHAINS[currentNetwork].eventsManagerContract,
                data: encodedABI,
            },
            title: i18next.t('action.createCollection'),
            successfulSendingCallback: () => {
                dispatch(requestSbtCollections())
                dispatch(setModalCreateSbtCollection(false))
            }
        })
        dispatch(setModalSendTransactions({transactions}))
        return true
    }
)
export const mintSbtInCollection = createAsyncThunk(
    'sbt/mintSbtInCollection',
    async ({asset, wrapFor}: {asset: IAssetItem, wrapFor: string}, {dispatch, getState}): Promise<boolean> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app
        const {selectedSbtCollection} = state.app

        if (!currentNetwork || !web3 || !walletAddress || !selectedSbtCollection) {
            return false
        }

        if (!ethers.utils.isAddress(wrapFor)) {
            dispatch(setModalError({text: i18next.t('error.wrongReceiverAddress'), buttons: ['close']}))
            return false
        }

        let transactions: ISendTransaction[] = []
        try {
            const tokenContract = new web3.eth.Contract(Erc721Abi as AbiItem[], asset.asset.contractAddress)
            if (!(await tokenContract.methods.isApprovedForAll(walletAddress, CHAINS[currentNetwork].wrapperBatchContract).call())) {
                const method = tokenContract.methods.setApprovalForAll(CHAINS[currentNetwork].wrapperBatchContract, true)
                const encodedABI = method.encodeABI()
                transactions.push({
                    trx: {
                        from: walletAddress,
                        to: asset.asset.contractAddress,
                        data: encodedABI,
                    },
                    title: i18next.t('action.setApprovalForTickets'),
                })
            }

        } catch (e) {
            console.log(e)
            dispatch(setModalError({text: i18next.t('error.approvingTicket'), buttons: ['close']}))
            return false
        }
        const wrapperContract = new web3.eth.Contract(CHAINS[currentNetwork].wrapperBatchContractAbi, CHAINS[currentNetwork].wrapperBatchContract)
        const inData: any[] = [asset, walletAddress, [], [], [], _AssetType.ERC721, 0, '0x0005']
        const method = wrapperContract.methods.wrapIn(inData, [], wrapFor, selectedSbtCollection)
        const encodedABI = method.encodeABI()
        transactions.push({
            trx: {
                from: walletAddress,
                to: CHAINS[currentNetwork].wrapperBatchContract,
                data: encodedABI,
//                gasLimit: ethers.utils.parseUnits('0.0005', 'gwei'),
            },
            title: i18next.t('button.createSbt'),
            successfulSendingCallback: () => {
                dispatch(requestTokens())
                dispatch(setModalMintSbt(null))
            }
        })
        dispatch(setModalSendTransactions({transactions}))
        return true
    }
)
export const requestSbtCollection = createAsyncThunk(
    'sbt/requestSbtCollection',
    async ({network, address}: {network: string, address: string}, {dispatch}): Promise<void> => {

        let response: SliceResponse = {}
        if (isNaN(Number(network))) {
            response.error = {text: i18next.t('error.wrongNetwork')}
        } else if (!ethers.utils.isAddress(address)) {
            response.error = {text: i18next.t('error.wrongAddress')}
        } else {
            try {
                const result = await axios.get(`${API_URL}sbt/collection/${Number(network)}/${address}`)
                if (result.status !== 200) {
                    throw new Error(i18next.t('error.wrongResponse', {name: result.data.error}))
                }

                response.status = result.status
                let collection: IWnft[] = []
                for (let item of result.data.tokens) {
                    collection.push({
                        ...item,
                        contract: item.contract.toLowerCase(),
                        owner: item.owner.toLowerCase(),
                    })
                }
                response.data = collection
            } catch (e: any) {
                response.defaultData = []
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setSbtCollection(value))
        }
        dispatch(checkResponse(response))
    }
)
export const requestSbtCollections = createAsyncThunk(
    'sbt/requestSbtCollections',
    async (network: string | undefined, {getState}): Promise<ICollection[] | null> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app

        if (!walletAddress) {
            return null
        }

        if (currentNetwork && web3 && currentNetwork === network) {
            try {
                const contract = new web3.eth.Contract(CHAINS[currentNetwork].eventsManagerContractAbi, CHAINS[currentNetwork].eventsManagerContract)
                const result = await contract.methods.getUsersCollections(walletAddress).call()
                let factories: ICollection[] = []
                for (let item of result) {
                    if (Number(item.assetType) !== _AssetType.ERC721) {
                        continue
                    }

                    const cntr = new web3.eth.Contract(CHAINS[currentNetwork].sbtImpl721ContractAbi, item.contractAddress)
                    const name = await cntr.methods.name().call()
                    factories.push({
                        assetType: Number(item.assetType),
                        contractAddress: item.contractAddress.toLowerCase(),
                        name,
                    })
                }
                return factories
            } catch (e) {
                console.log(e)
            }
        } else if (network) {
            try {
                const provider = ethers.getDefaultProvider(CHAINS[network].rpcUrl)
                const contract = new ethers.Contract(
                    CHAINS[network].eventsManagerContract,
                    new ethers.utils.Interface(JSON.stringify(CHAINS[network].eventsManagerContractAbi)),
                    provider
                )
                const result = await contract.getUsersCollections(walletAddress)
                let factories: ICollection[] = []
                for (let item of result) {
                    if (Number(item.assetType) !== _AssetType.ERC721) {
                        continue
                    }

                    const cntr = new ethers.Contract(
                        item.contractAddress,
                        new ethers.utils.Interface(JSON.stringify(CHAINS[network].sbtImpl721ContractAbi)),
                        provider
                    )
                    const name = await cntr.name()
                    factories.push({
                        assetType: Number(item.assetType),
                        contractAddress: item.contractAddress.toLowerCase(),
                        name,
                    })
                }
                return factories
            } catch (e) {
                console.log(e)
            }
        }
        return []
    }
)

export const sbtSlice = createSlice({
    name: 'sbt',
    initialState,
    reducers: {
        resetState: (state) => {
            let key: keyof SbtState
            for (key in initialState) {
                Reflect.set(state, key, initialState[key])
            }
        },
        setCollections: (state, action: PayloadAction<ICollection[] | null>) => {
            state.collections = action.payload
        },
        setSbtCollection: (state, action: PayloadAction<IWnft[] | null>) => {
            state.sbtCollection = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestSbtCollections.fulfilled, (state, action: PayloadAction<ICollection[] | null>) => {
            state.collections = action.payload
        })
    },
})

export const getCollectionName = (contract: string | undefined) => (state: RootState): string | null => {
    let name = null
    for (let item of state.sbt.collections || []) {
        if (item.contractAddress === contract) {
            name = item.name
            break
        }
    }
    return name
}
export const getCollections = (state: RootState) => state.sbt.collections
export const getSbtCollection = (state: RootState) => state.sbt.sbtCollection
/*
export const getSelectedCollectionName = (state: RootState) => {
    let name = ''
    if (state.sbt.selectedCollection) {
        for (let item of state.sbt.collections || []) {
            if (item.contractAddress === state.sbt.selectedCollection) {
                name = item.name
                break
            }
        }
    }
    return name
}
*/

export const {
    resetState,
    setCollections,
    setSbtCollection,
} = sbtSlice.actions

export default sbtSlice.reducer
