import React, {ReactNode} from 'react'

interface propsType {
    additionalClass?: string
    error?: boolean
    errorText?: string
    label?: ReactNode
    onChange: (value: string) => void
    rows?: number
    value: string
}

const TextareaElement = (props: propsType) => {
    return <>
        {props.label ?
            <label className="form-label">{props.label}</label>
            :
            null
        }
        <textarea
            className={`form-control ${props.error ? 'is-invalid' : ''} ${props.additionalClass || ''}`}
            rows={props.rows || 3}
            value={props.value}
            onChange={(e) => {
                props.onChange(e.target.value)
            }}
        ></textarea>
        {props.errorText !== '' ?
            <div className="invalid-feedback">{props.errorText}</div>
            :
            null
        }
    </>
}

export default TextareaElement
