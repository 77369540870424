import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import i18next from 'i18next'
import {setToWalletStorage} from './storage'
import {RootState} from './store'
import {DBOrganizer, SliceResponse} from './types'
import {API_URL} from '../utils/constants'
import {checkResponse, setModalEditString} from './appSlice'

interface OrganizersState {
    organizers: DBOrganizer[] | null
    selectedOrganizerId: number | null
}

const initialState: OrganizersState = {
    organizers: null,
    selectedOrganizerId: null,
}

export const putOrganizerTitle = createAsyncThunk(
    'organizers/putOrganizerTitle',
    async (title: string, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        const {selectedOrganizerId} = state.organizers

        let response: SliceResponse = {}
        if (!jwt) {
            response.error = {text: i18next.t('error.notAuthorized')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const body = {title}
                const result = await axios.put(`${API_URL}organizers/${selectedOrganizerId}/title`, body, config)
                response.status = result.status
                response.data = null
                response.successCallback = () => {
                    dispatch(setModalEditString(null))
                    dispatch(setOrganizers(null))
                }
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        dispatch(checkResponse(response))
    }
)

export const requestOrganizers = createAsyncThunk(
    'organizers/requestOrganizers',
    async (_, {getState, dispatch}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        const {walletAddress} = state.app

        let response: SliceResponse = {}
        if (!jwt || !walletAddress) {
            response.error = {text: i18next.t('error.jwtOrUserNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.get(`${API_URL}organizers/${walletAddress}`, config)
                let organizers: DBOrganizer[] = []
                for (let item of result.data.organizers) {
                    organizers.push({name: item.title, id: item.id, url: item.url})
                }
                response.status = result.status
                response.data = organizers
            } catch (e: any) {
                response.defaultData = []
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setOrganizers(value))
        }
        dispatch(checkResponse(response))
    }
)

export const organizersSlice = createSlice({
    name: 'organizers',
    initialState,
    reducers: {
        setOrganizers: (state, action: PayloadAction<DBOrganizer[] | null>) => {
            state.organizers = action.payload
        },
        setSelectedOrganizerId: (state, action: PayloadAction<number | null>) => {
            state.selectedOrganizerId = action.payload
            setToWalletStorage('organizerId', action.payload)
        },
    },
})

export const getOrganizers = (state: RootState): DBOrganizer[] | null => state.organizers.organizers
export const getSelectedOrganizerId = (state: RootState): number | null => state.organizers.selectedOrganizerId
export const getSelectedOrganizerName = (state: RootState): string => {
    if (state.organizers.organizers && state.organizers.selectedOrganizerId) {
        for (let item of state.organizers.organizers) {
            if (item.id === state.organizers.selectedOrganizerId) {
                return item.name
            }
        }
//        state.organizers.selectedOrganizerId = null
        setToWalletStorage('organizerId', null)
    }
    return ''
}

export const {setOrganizers, setSelectedOrganizerId} = organizersSlice.actions

export default organizersSlice.reducer
