import React, {ReactNode, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, NavLink, useParams} from 'react-router-dom'
import {Trans, useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {
    getCurrentNetwork,
    getWalletAddress, sendRequestWithAuth,
    setModalAddEventToShowcase,
    setModalConfirmation,
    setModalEditShowcase, setRedirectPath
} from '../../store/appSlice'
import {CHAINS, SHOWCASE_TOKENS_ON_PAGE} from '../../utils/constants'
import {getCoins, requestCoins, requestCustomCoins} from '../../store/coinsSlice'
import {ShowcaseV2TokenCard} from '../tokens'
import {NotFound} from '../static'
import {getDateRangeString, getDisplayHash} from '../../utils/functions'
import {AddTicketIcon, BackIcon, CrossIcon, EditIcon, LinkIcon, LoadingIcon} from '../icons'
import {AlertElement, ButtonElement} from '../elements'
import {delShowcaseFromEvent} from '../../store/eventsSlice'
import {
    getShowcase,
    getShowcaseEvents,
    requestShowcase,
    requestShowcaseEvents,
    setShowcase, setShowcaseEvents
} from '../../store/showcaseV2Slice'

const Showcase = () => {
    const {t} = useTranslation()
    let {network, showcaseName} = useParams()
    network = `0x${Number(network).toString(16)}`
    if (!CHAINS[network]) {
        network = undefined
    }
    const [loadedShowcase, setLoadedShowcase] = useState(false)
    const [nextPage, setNextPage] = useState(1)
    const coins = useSelector(getCoins)
    const currentNetwork = useSelector(getCurrentNetwork)
    const showcase = useSelector(getShowcase)
    const showcaseEvents = useSelector(getShowcaseEvents)
    const currentDate = Math.floor(Date.now() / 1000)
    const showcaseDisabled = !!showcase && (showcase.enableAfter > currentDate || showcase.disableAfter < currentDate)
    const walletAddress = useSelector(getWalletAddress)
    const showcasesLink = currentNetwork && showcase && showcase.priceModel === CHAINS[currentNetwork].showcaseV2PriceModel.classic ?
        '/classic/showcases'
        :
        '/lazy/showcases'
    const datesString = showcase ? getDateRangeString(new Date(showcase.enableAfter * 1000), new Date(showcase.disableAfter * 1000)) : ''

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        console.log(`showcase name hash: ${getDisplayHash(showcaseName || '')}`)
        dispatch(requestCoins(network))
        return () => {
            dispatch(setShowcase(null))
        }
    }, [network, showcaseName])
    useEffect(() => {
        if (showcaseName) {
            if (loadedShowcase && !showcase) {
                setLoadedShowcase(false)
            } else if (!loadedShowcase && showcase && network) {
                setLoadedShowcase(true)
                dispatch(requestCustomCoins(network))
            } else if (!loadedShowcase && !showcase) {
                dispatch(requestShowcase({showcaseName: showcaseName, network}))
            }
        }
    }, [loadedShowcase, network, showcase])
    useEffect(() => {
        if (showcaseName && loadedShowcase && showcase && network && !showcaseEvents) {
            dispatch(requestShowcaseEvents({
                contract: CHAINS[network].showcaseV2Contract,
                displayName: showcaseName,
                network
            }))
        }
        if (showcaseEvents) {
            return () => {
                dispatch(setShowcaseEvents(null))
            }
        }
    }, [loadedShowcase, network, showcase, showcaseEvents])

    const tokenList = useMemo(() => {
        if (!showcase) {
            return null
        }

        if (showcase.items.length === 0) {
            if (walletAddress === showcase.owner) {
                return <AlertElement centered type={'warning'}>
                    <Trans
                        i18nKey={'showcase.emptyShowcase'}
                        components={[<Link to={showcasesLink}/>, <a href={'#'}/>]}
                    />
                </AlertElement>
            } else {
                return <AlertElement centered type={'warning'}>{t('status.showcaseEmpty')}</AlertElement>
            }
        }

        let list: ReactNode[] = showcase.items.slice(0, SHOWCASE_TOKENS_ON_PAGE * nextPage).map((item) => (
            <ShowcaseV2TokenCard
                key={`${item.nft.asset.contractAddress}-${item.nft.tokenId}`}
                tokenCard={item}
                enableAfter={showcase.enableAfter}
                disableAfter={showcase.disableAfter}
                owner={showcase.owner}
                network={network}
                eventLink={showcaseEvents && showcaseEvents[0] ? `/event/${showcaseEvents[0].organizerUrl}/${showcaseEvents[0].url}` : ''}
            />))
        if (showcase.items.length > SHOWCASE_TOKENS_ON_PAGE * nextPage) {
            list.push(<ButtonElement
                key={'loadMore'}
                outline
                onClick={() => {
                    setNextPage(nextPage + 1)
                }}
            >{t('button.loadMore')}</ButtonElement>)
        }
        return list
    }, [showcase, showcaseEvents, coins, nextPage, walletAddress, currentNetwork])

    if (!showcaseName) {
        return <NotFound/>
    }

    const addEventHandler = () => {
        if (network && showcaseName) {
            dispatch(setModalAddEventToShowcase({
                contract: CHAINS[network].showcaseV2Contract,
                network,
                showcaseName,
            }))
        }
    }
    const addTicketsHandler = () => {
        dispatch(setRedirectPath(showcasesLink))
    }
    const delEventHandler = (eventId: number) => {
        let name = ''
        for (let item of showcaseEvents || []) {
            if (item.id === eventId) {
                name = item.title
                break
            }
        }
        let showcaseId = 0
        for (let item of showcaseEvents || []) {
            if (item.id === eventId) {
                for (let show of item.showcases || []) {
                    if (network && show.chain === Number(network) &&
                        show.contract === CHAINS[network].showcaseV2Contract && show.name === showcaseName
                    ) {
                        showcaseId = show.id
                        break
                    }
                }
            }
        }
        if (name === '' || showcaseId === 0) {
            return
        }

        dispatch(setModalConfirmation({
            title: t('modal.question.deleteEventFromShowcase', {name}),
            confirmAction: () => {
                dispatch(sendRequestWithAuth(delShowcaseFromEvent({eventId, showcaseId})))
            },
        }))
    }
    const editShowcaseHandler = () => {
        dispatch(setModalEditShowcase(showcaseName || ''))
    }

    return <div className="page-content">
        <div className="container">
            <nav>
                <ol className="breadcrumb align-items-center">
                    <li className="breadcrumb-item">
                        <NavLink to={showcasesLink}>
                            <BackIcon className={'me-2 mt-n1'}/>
                            <span>{t('section.showcaseManagement')}</span>
                        </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">{t('section.view')}</li>
                </ol>
            </nav>
        </div>
        <div className="container">
            <div className="row mb-4 pb-4">
                <div className="col mb-4">
                    <h1 className="mb-3">{showcaseName}</h1>
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <span className="text-muted me-2">{t('word.dates')}:</span>
                            <span>{datesString}</span>
                        </div>
                        <div className="col-auto">
                            <span className="text-muted me-2">{t('word.items')}:</span>
                            <span>{showcase?.items.length}</span>
                        </div>
                    </div>
                </div>
                {showcase?.owner === walletAddress ?
                    <div className="col-12 col-md-auto">
                        <div className="btn-group w-100">
                            <ButtonElement small outline onClick={editShowcaseHandler}>
                                <span className="me-2"><EditIcon/></span>
                                <span>{t('button.edit')}</span>
                            </ButtonElement>
                            <ButtonElement
                                small
                                onClick={addTicketsHandler}
                            >
                                <span className="me-2"><AddTicketIcon/></span>
                                <span>{t('button.addTickets')}</span>
                            </ButtonElement>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
            {showcaseDisabled ?
                <AlertElement centered type={'warning'}>{t('status.showcaseNotActive')}</AlertElement>
                :
                null
            }
            {showcase?.owner === walletAddress ?
                <div className="border rounded px-3 py-1 mb-2">
                    <div className="row gx-3 align-items-lg-center">
                        {showcaseEvents && showcaseEvents.length ?
                            <div className="col py-1">
                                <div className="mt-1">
                                    {t('showcase.goToEvent1')}
                                    {' '}
                                    {showcaseEvents.map((item, index) => (
                                        <span className="d-inline-block me-2" key={item.id}>
                                            <NavLink
                                                to={`/event/${item.organizerUrl}/${item.url}`}>{item.title}</NavLink>
                                            {' '}
                                            {showcase?.owner === walletAddress ?
                                                <ButtonElement
                                                    small
                                                    className={'btn-with-opacity p-0'}
                                                    onClick={() => delEventHandler(item.id)}
                                                >
                                                    <CrossIcon/>
                                                </ButtonElement>
                                                :
                                                index !== showcaseEvents.length - 1 ? ', ' : ''
                                            }
                                    </span>
                                    ))}
                                    {' '}
                                    {t('showcase.goToEvent2')}
                                </div>
                            </div>
                            :
                            null
                        }
                        {showcase?.owner === walletAddress ?
                            <div className="col-12 col-md-auto">
                                <ButtonElement
                                    small
                                    outline
                                    additionalClass={'w-100 my-3 my-md-2'}
                                    onClick={addEventHandler}
                                >
                                    <span className="me-2"><LinkIcon/></span>
                                    <span>{t('button.linkEvent')}</span>
                                </ButtonElement>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                :
                showcaseEvents && showcaseEvents.length ?
                    <div className="alert alert-secondary">
                        {t('showcase.goToEvent1')}
                        {' '}
                        {showcaseEvents.map((item, index) => (
                            <span className="d-inline-block me-2" key={item.id}>
                                <NavLink to={`/event/${item.organizerUrl}/${item.url}`}>{item.title}</NavLink>
                                {index !== showcaseEvents.length - 1 ? ', ' : ''}
                            </span>
                        ))}
                        {' '}
                        {t('showcase.goToEvent2')}
                    </div>
                    :
                    null
            }
                {showcase ?
                    network ?
                        <div className="row mt-4">{tokenList}</div>
                        :
                        <AlertElement centered type={'danger'}>{t('error.wrongUri')}</AlertElement>
                    :
                    <LoadingIcon/>
                }
        </div>
    </div>
}

export default Showcase
