import React from 'react'
import {IconProps} from '../../store/types'

const TwitterIcon = (props: IconProps) => {
    return <svg className={props.className} width="25" height="25" viewBox="0 0 25 25" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12.5 25a12.5 12.5 0 1 0 0-25 12.5 12.5 0 0 0 0 25Zm6.91-17.1c.26-.07.5-.16.75-.27a.25.25 0 0 1 .3.37c-.4.6-.9 1.14-1.47 1.57a9 9 0 0 1-2.31 6.33A8.6 8.6 0 0 1 10 18.83c-1.72 0-3.4-.5-4.84-1.42a.25.25 0 0 1 .17-.46 6 6 0 0 0 3.84-.85 3.32 3.32 0 0 1-2.43-2.23.25.25 0 0 1 .28-.32l.25.04a3.33 3.33 0 0 1-1.62-2.85v-.05a.25.25 0 0 1 .37-.21c.14.08.29.14.44.2a3.31 3.31 0 0 1-.34-3.82.25.25 0 0 1 .41-.03 8.5 8.5 0 0 0 5.83 3.09 3.32 3.32 0 0 1 5.61-2.8c.62-.14 1.2-.37 1.74-.69a.25.25 0 0 1 .37.3 3.3 3.3 0 0 1-.68 1.18Z"></path>
    </svg>
}

export default TwitterIcon
