import React, {useEffect, useMemo, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {NavLink, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {CHAINS} from '../../utils/constants'
import {NotFound} from '../static'
import {
    getCurrentOnChainEvent, getUserSbts, getUserTickets, getUserUsedTickets,
    requestOnChainEvent, requestUserTickets,
    requestUserWnfts,
    setCurrentOnChainEvent, setUserTickets, setUserWnfts
} from '../../store/onChainEventsSlice'
import {getCurrentNetwork, getWalletAddress, sendRequestWithAuth} from '../../store/appSlice'
import {AlertElement, CopyToClipboardBlock} from '../elements'
import {OnChainTicketRow} from '../tickets'
import {getUser} from '../../store/authSlice'
import {compactString} from '../../utils/functions'
import {LoadingIcon} from '../icons'

const OnChainEvent = () => {
    const {t} = useTranslation()
    let {network, contractAddress} = useParams()
    network = `0x${Number(network).toString(16)}`
    if (!CHAINS[network]) {
        network = undefined
    }
    const [activeTab, setActiveTab] = useState(0)
    const [loadedEvent, setLoadedEvent] = useState(false)
    const currentNetwork = useSelector(getCurrentNetwork)
    const event = useSelector(getCurrentOnChainEvent)
    const user = useSelector(getUser)
    const userSbts = useSelector(getUserSbts, shallowEqual)
    const userTickets = useSelector(getUserTickets)
    const userUsedTickets = useSelector(getUserUsedTickets, shallowEqual)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (contractAddress && network) {
            dispatch(requestOnChainEvent({network, address: contractAddress}))
        }
        return () => {
            dispatch(setCurrentOnChainEvent(null))
        }
    }, [contractAddress, currentNetwork, network])
    useEffect(() => {
        if (currentNetwork && event && walletAddress && user?.auth) {
            dispatch(sendRequestWithAuth(requestUserWnfts()))
        }
        return () => {
            dispatch(setUserWnfts(null))
        }
    }, [currentNetwork, event, user, walletAddress])
    useEffect(() => {
        if (currentNetwork && event && walletAddress) {
            dispatch(requestUserTickets(event.tickets))
        }
        return () => {
            dispatch(setUserTickets(null))
        }
    }, [currentNetwork, event, walletAddress])
    useEffect(() => {
        if (loadedEvent && !event && contractAddress) {
            setLoadedEvent(false)
            dispatch(requestOnChainEvent({network: network || '', address: contractAddress}))
        } else if (!loadedEvent && event) {
            setLoadedEvent(true)
        }
    }, [event])

    const ticketsList = useMemo(() => {
        if (!userTickets) {
            return <LoadingIcon/>
        }

        return userTickets.length > 0 ? userTickets.map((item, index) => (
            <OnChainTicketRow item={item} key={index}/>
        )) : <AlertElement centered>Tickets not found</AlertElement>
    }, [userTickets])
    const usedTicketsList = useMemo(() => {
        if (!userUsedTickets) {
            return <LoadingIcon/>
        }

        return userUsedTickets.length > 0 ? userUsedTickets.map((item, index) => (
            <OnChainTicketRow item={item} key={index}/>
        )) : <AlertElement centered>{t('error.ticketsNotFound')}</AlertElement>
    }, [userUsedTickets])
    const sbtsList = useMemo(() => {
        if (!userSbts) {
            return <LoadingIcon/>
        }

        return userSbts.length > 0 ? userSbts.map((item, index) => (
            <OnChainTicketRow item={item} key={index}/>
        )) : <AlertElement centered>{t('error.sbtsNotFound')}</AlertElement>
    }, [userSbts])

    if (!contractAddress || !network) {
        return <NotFound/>
    }

    return <div className="page-content">
        <div className="container">
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={'/events/management'}>{t('section.myEvents')}</NavLink></li>
                    <li className="breadcrumb-item active" aria-current="page">{event?.eventName || contractAddress}</li>
                </ol>
            </nav>
        </div>
        <div className="container">
            <h1 className="mb-3">{event?.eventName || contractAddress}</h1>
            {event !== null ?
                event ?
                    <>
                        <div className="mb-4 pb-2 mb-lg-2 pb-lg-0">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <span className="text-muted me-2">{t('word.contract')}:</span>
                                    <span>{compactString(contractAddress)}</span>
                                    <CopyToClipboardBlock text={contractAddress}/>
                                </div>
                                <div className="col-auto">
                                    <span className="text-muted me-2">{t('word.ticker')}:</span>
                                    <span>{event.eventTicker}</span>
                                </div>
                            </div>
                        </div>
                        <ul className="nav nav-tabs mt-lg-6" id="myEvent" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link ${activeTab === 0 ? 'active' : ''}`}
                                    id="tickets-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#tickets-tab-pane"
                                    type="button"
                                    role="tab"
                                    aria-controls="home-tab-pane"
                                    aria-selected={activeTab === 0}
                                    onClick={() => setActiveTab(0)}
                                >{t('button.tickets')}</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
                                    id="used-tickets-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#used-tickets-tab-pane"
                                    type="button"
                                    role="tab"
                                    aria-controls="profile-tab-pane"
                                    aria-selected={activeTab === 1}
                                    onClick={() => setActiveTab(1)}
                                >{t('button.usedTickets')}</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link ${activeTab === 2 ? 'active' : ''}`}
                                    id="certificates-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#certificates-tab-pane"
                                    type="button"
                                    role="tab"
                                    aria-controls="contact-tab-pane"
                                    aria-selected={activeTab === 2}
                                    onClick={() => setActiveTab(2)}
                                >{t('button.certificates')}</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myEventContent">
                            <div
                                className={`tab-pane fade ${activeTab === 0 ? 'show active' : ''}`}
                                id="tickets-tab-pane"
                                role="tabpanel"
                                aria-labelledby="tickets-tab"
                                tabIndex={0}
                            >
                                <div className="pt-4">
                                    <div className="mb-4">
                                        <span className="text-muted me-2">Use dates:</span>
                                        <span>
                                            {(new Date(event.useTicket.start * 1000)).toDateString()}
                                            {' - '}
                                            {(new Date(event.useTicket.finish * 1000)).toDateString()}
                                        </span>
                                    </div>
                                    <div className="row-table">
                                        {ticketsList}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`}
                                id="used-tickets-tab-pane"
                                role="tabpanel"
                                aria-labelledby="used-tickets-tab"
                                tabIndex={0}
                            >
                                <div className="pt-4">
                                    <div className="mb-4">
                                        <span className="text-muted me-2">{t('event.createDates')}:</span>
                                        <span>
                                            {(new Date(event.certificate.start * 1000)).toDateString()}
                                            {' - '}
                                            {(new Date(event.certificate.finish * 1000)).toDateString()}
                                        </span>
                                    </div>
                                    <div className="row-table">
                                        {usedTicketsList}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`tab-pane fade ${activeTab === 2 ? 'show active' : ''}`}
                                id="certificates-tab-pane"
                                role="tabpanel"
                                aria-labelledby="certificates-tab"
                                tabIndex={0}
                            >
                                <div className="pt-4">
                                    <div className="row-table">
                                        {sbtsList}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    null
                :
                <LoadingIcon/>
            }
        </div>
    </div>
}

export default OnChainEvent
