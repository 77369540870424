import React, {useEffect, useMemo, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {Trans, useTranslation} from 'react-i18next'
import {SelectSbtCollection} from './index'
import {AppDispatch} from '../../store/store'
import {
    getCurrentNetwork,
    getSelectedSbtCollection,
    getTokenSmartcontracts,
    getWalletAddress
} from '../../store/appSlice'
import {getTickets, requestTokens, resetTickets, setTickets} from '../../store/ticketsSlice'
import {getCollections} from '../../store/sbtSlice'
import {ButtonElement, CheckboxElement, DropdownSelector, IDropdownItem, InputElement} from '../elements'
import {getEventsObject} from '../../store/eventsSlice'
import {FiltersIcon} from '../icons'
import {SbtTicketRow} from '../tickets'

const SbtCollectionPage = () => {
    const {t} = useTranslation()
    const [addressFilter, setAddressFilter] = useState('')
    const [checkedSmartcontracts, setCheckedSmartcontracts] = useState(true)
    const [eventsFilter, setEventsFilter] = useState<IDropdownItem[]>([])
    const [levelsFilter, setLevelsFilter] = useState<IDropdownItem[]>([])
    const [selectedEventFilter, setSelectedEventFilter] = useState(0)
    const [selectedLevelFilter, setSelectedLevelFilter] = useState('')
    const [showFilters, setShowFilters] = useState(false)
    const collections = useSelector(getCollections)
    const currentNetwork = useSelector(getCurrentNetwork)
    const events = useSelector(getEventsObject)
    const selectedCollection = useSelector(getSelectedSbtCollection)
    const tickets = useSelector(getTickets)
    const tokenSmartcontracts = useSelector(getTokenSmartcontracts, shallowEqual)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        const timer = setInterval(() => {
            dispatch(requestTokens())
        }, 300000)

        return () => {
            clearInterval(timer)
            dispatch(resetTickets())
        }
    }, [])
    useEffect(() => {
        if (tickets) {
            dispatch(setTickets(null))
        } else if (walletAddress) {
            dispatch(requestTokens())
        }
    }, [currentNetwork, walletAddress])
    useEffect(() => {
        if (!tickets && walletAddress) {
            dispatch(requestTokens())
        }
        let eventsList: IDropdownItem[] = [{id: 0, name: t('form.label.allEvents')}]
        let levelsList: IDropdownItem[] = [{id: '', name: t('form.label.allLevels')}]
        let ids: number[] = []
        let ids2: string[] = []
        for (let item of tickets || []) {
            if (item.eventId && events && events[item.eventId] && ids.indexOf(item.eventId) < 0) {
                eventsList.push({id: item.eventId, name: events[item.eventId].title})
                ids.push(item.eventId)
            }
            if (item.level && ids2.indexOf(item.level.title) < 0) {
                levelsList.push({id: item.level.title, name: item.level.title})
                ids2.push(item.level.title)
            }
        }
        setEventsFilter(eventsList)
        setLevelsFilter(levelsList)
    }, [tickets, events])

    const ticketsList = useMemo(() => {
        if (!tickets || !currentNetwork) {
            return <div>{t('status.loadingTicketsDescription')}</div>
        }

        if (tickets.length === 0) {
            return <div>
                <Trans i18nKey={'certificate.createTicketsDescription'} components={[<Link to={'/mintfactory'}/>]}/>
            </div>
        }

        let list = tickets.filter((item) => {
            return item.tokenId.toString().indexOf(addressFilter) >= 0 || item.contract.indexOf(addressFilter.toLowerCase()) >= 0
        }).filter((item) => {
            return selectedEventFilter === 0 || item.eventId === selectedEventFilter
        }).filter((item) => {
            return selectedLevelFilter === '' || (item.level && item.level.title === selectedLevelFilter)
        }).filter((item) => {
            return !checkedSmartcontracts || tokenSmartcontracts.indexOf(item.contract) >= 0
        })
        if (list.length === 0) {
            return <div>{t('status.noNftFitsFilter')}</div>
        } else {
            return list.map((item) => (<SbtTicketRow key={`${item.contract}-${item.tokenId}`} item={item}/>))
        }
    }, [tickets, addressFilter, checkedSmartcontracts, selectedEventFilter, selectedLevelFilter])

    const addressFilterHandler = (value: string) => {
        if (value === '' || /^[a-zA-Z0-9]+$/.test(value)) {
            setAddressFilter(value)
        }
    }

    return <div className="page-content">
        <div className="container">
            <h1>{t('header.certificates')}</h1>
            <SelectSbtCollection/>
            {collections && selectedCollection ?
                <>
                    <div className="row gx-3 gx-xl-4 mb-2 mb-xl-2">
                        <div
                            className="col-12 col-md-4 col-lg-auto mb-3 order-1 order-md-0 col-filter__mob-toggled"
                            style={showFilters ? {display: 'block'} : {}}
                        >
                            <div className="btn-group w-100">
                                <div className="btn-group flex-grow-1 dropdown">
                                    <button
                                        className="btn btn-sm btn-outline-primary btn-filter-select dropdown-toggle">
                                        <span className="me-2">{t('element.anyDate')}</span>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M12.7 15.1a1 1 0 0 1-1.4 0l-4.8-4.8a1 1 0 1 1 1.4-1.4l4.1 4.09 4.1-4.1a1 1 0 1 1 1.4 1.42l-4.8 4.8Z"></path>
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu">Here comes the calendar</div>
                                </div>
                                <button className="btn btn-sm btn-outline-primary flex-grow-0">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M11.3 4.1a1 1 0 0 1 1.4 0l3.6 3.6a1 1 0 0 1-1.4 1.4L12 6.22l-2.9 2.9a1 1 0 1 1-1.4-1.42l3.6-3.6ZM7.7 14.9a1 1 0 0 1 1.4 0l2.9 2.89 2.9-2.9a1 1 0 0 1 1.4 1.42l-3.6 3.6a1 1 0 0 1-1.4 0l-3.6-3.6a1 1 0 0 1 0-1.42Z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div
                            className="col-12 col-md-4 col-lg-2 mb-3 order-1 order-md-0 col-filter__mob-toggled"
                            style={showFilters ? {display: 'block'} : {}}
                        >
                            <DropdownSelector
                                list={eventsFilter}
                                setItem={setSelectedEventFilter}
                                currentItem={selectedEventFilter}
                                button
                            />
                        </div>
                        <div
                            className="col-12 col-md-4 col-lg-2 mb-3 order-1 order-md-0 col-filter__mob-toggled"
                            style={showFilters ? {display: 'block'} : {}}
                        >
                            <DropdownSelector
                                list={levelsFilter}
                                setItem={setSelectedLevelFilter}
                                currentItem={selectedLevelFilter}
                                button
                            />
                        </div>
                        <div className="col-8 col-md-4 col-lg-3 col-xl-2 mb-4 order-0">
                            <InputElement
                                value={addressFilter}
                                onChange={addressFilterHandler}
                                additionalClass={'form-control form-control-sm control-search'}
                                placeholder={t('form.label.addressOrId')}
                            />
                        </div>
                        <div className="col-4 mb-4 order-0 d-md-none">
                            <ButtonElement
                                small
                                outline
                                additionalClass={`btn-filter-toggle w-100 ${showFilters ? 'show' : ''}`}
                                onClick={() => {setShowFilters(!showFilters)}}
                            >
                                <FiltersIcon className="me-2"/>
                                <span>{t('button.filters')}</span>
                            </ButtonElement>
                        </div>
                        <div
                            className="col-md-8 col-lg-auto order-1 mb-4 col-filter__mob-toggled"
                            style={showFilters ? {display: 'block'} : {}}
                        >
                            <CheckboxElement
                                checked={checkedSmartcontracts}
                                onChange={() => {setCheckedSmartcontracts(!checkedSmartcontracts)}}
                                label={t('form.label.myshchSmartContracts')}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="row-table">
                            {ticketsList}
                        </div>
                    </div>
                </>
                :
                null
            }
        </div>
    </div>
}

export default SbtCollectionPage
