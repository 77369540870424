import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import {ethers} from 'ethers'
import i18next from 'i18next'
import {RootState} from './store'
import {_AssetType, API_URL, CHAINS} from '../utils/constants'
import {createIpfsLink} from '../utils/functions'
import {ISendTransaction, IToken} from './types'
import {setModalSendToken, setModalSendTransactions} from './appSlice'
import {setTickets} from './ticketsSlice'

interface TokensState {
    tokens: ITokens | null
}

interface ITokens {
    [key: string]: IToken
}

const initialState: TokensState = {
    tokens: null,
}

export const requestToken = createAsyncThunk(
    'tokens/requestToken',
    async (params: {address: string, tokenId: bigint, network: string}): Promise<IToken | null> => {
        const {address, tokenId, network} = params

        if (!ethers.utils.isAddress(address)) {
            console.log(`Wrong contract address (${address}) or tokenId (${tokenId.toString()})`)
            return null
        }

        let tokenUri = ''
        let owner = ''
        let blockNum: number = NaN
        let date: Date | undefined
        try {
            const result = await axios.get(`${API_URL}oracle/discover/721/${Number(network)}/${address}/${tokenId}`)
            if (result.data) {
                tokenUri = result.data.tokenUri || ''
                owner = result.data.owner
                blockNum = Number(result.data.blockNum)
                const provider = new ethers.providers.JsonRpcProvider(CHAINS[network].rpcUrl)
                date = new Date((await provider.getBlock(blockNum)).timestamp * 1000)
            }
        } catch (e) {
            console.log(e)
            return null
        }
        if (tokenUri === '') {
            console.log('tokenUri is empty')
            return null
        }

        tokenUri = createIpfsLink(tokenUri)
        let image = ''
        try {
            const metadata = await axios.get(tokenUri)
            image = metadata.data.image
        } catch (e) {
            console.log('Can\'t load metadata')
            console.log(e)
        }
        return {
            network: network,
            assetType: _AssetType.ERC721,
            contract: address,
            image: createIpfsLink(image),
            tokenId,
            tokenUri: createIpfsLink(tokenUri),
            owner: owner.toLowerCase(),
            blockNum,
            date,
        }
    }
)
export const sendToken = createAsyncThunk(
    'tokens/sendToken',
    async (to: string, {dispatch, getState}): Promise<boolean> => {
        const state = getState() as RootState
        const {currentNetwork, modalSendToken, walletAddress, web3} = state.app

        if (!currentNetwork || !modalSendToken || !walletAddress || !web3) {
            return false
        }

        if (modalSendToken.assetType !== _AssetType.ERC721) {
            console.log('Wrong token type')
            return false
        }

        let transactions: ISendTransaction[] = []
        const contract = new web3.eth.Contract(CHAINS[currentNetwork].nftMinterContract721Abi, modalSendToken.contract)
        const launchMethod = contract.methods.safeTransferFrom(walletAddress, to, modalSendToken.tokenId)
        const encodedABI = launchMethod.encodeABI()
        transactions.push({
            trx: {
                from: walletAddress,
                to: modalSendToken.contract,
                data: encodedABI,
            },
            title: i18next.t('action.sendToken'),
            successfulSendingCallback: () => {
                dispatch(setTickets(null))
                dispatch(setModalSendToken(null))
            }
        })
        dispatch(setModalSendTransactions({transactions}))
        return true
    }
)

export const tokensSlice = createSlice({
    name: 'tokens',
    initialState,
    reducers: {
        resetTokens: (state) => {
            state.tokens = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestToken.fulfilled, (state, action: PayloadAction<IToken | null>) => {
            if (action.payload) {
                if (!state.tokens) {
                    state.tokens = {}
                }
                state.tokens[`${action.payload.contract}-${action.payload.tokenId}`] = action.payload
            }
        })
    },
})

export const getToken = (address: string, tokenId: bigint) => (state: RootState): IToken | null => {
    if (!state.tokens.tokens) {
        return null
    }

    return state.tokens.tokens[`${address}-${tokenId.toString()}`]
}
export const getTokens = (state: RootState): ITokens | null => state.tokens.tokens

export const {
    resetTokens,
} = tokensSlice.actions

export default tokensSlice.reducer
