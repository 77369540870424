import React, {useContext, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useSearchParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {getEvents, requestEventsWithFilter, setEvents} from '../../store/eventsSlice'
import {AlertElement, ButtonElement, DropdownSelector, IDropdownItem} from '../elements'
import {EVENT_TYPE} from '../../utils/constants'
import {EventCardWithLink} from './index'
import {getWalletAddress, setModalCreateEvent} from '../../store/appSlice'
import {DBEventWithOrganizer} from '../../store/types'
import {LoadingIcon} from '../icons'
import {ThemeContext} from '../../context'
import {getEventTypeList, getMonthList} from '../../utils/functions'

const Events = () => {
    const {t} = useTranslation()
    const {showcaseType} = useContext(ThemeContext)
    const [searchParams, setSearchParams] = useSearchParams('')
    let month = NaN
    let year = NaN
    if (searchParams.has('month') && searchParams.get('month') !== '') {
        month = Number(searchParams.get('month'))
    }
    if (searchParams.has('year') && searchParams.get('year') !== '') {
        year = Number(searchParams.get('year'))
    }
    const currentYear = (new Date()).getFullYear()
    const [firstEventsRequest, setFirstEventsRequest] = useState(true)
    const [selectedMonth, setSelectedMonth] = useState(isNaN(month) ? (new Date()).getUTCMonth() : month)
    const [selectedYear, setSelectedYear] = useState(isNaN(year) ? currentYear : year)
    const [eventType, setEventType] = useState(EVENT_TYPE.All)
    let yearsArray: IDropdownItem[] = []
    for (let i = 2022; i < currentYear + 3; i++) {
        yearsArray.push({id: i, name: i.toString()})
    }
    const events = useSelector(getEvents)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (firstEventsRequest) {
            setFirstEventsRequest(false)
            return
        }

        if (!events) {
            dispatch(requestEventsWithFilter({month: selectedMonth, year: selectedYear}))
        }
    }, [events])
    useEffect(() => {
        if (events) {
            dispatch(setEvents(null))
        }

        searchParams.set('month', selectedMonth.toString())
        searchParams.set('year', selectedYear.toString())
        setSearchParams(searchParams)
        dispatch(requestEventsWithFilter({month: selectedMonth, year: selectedYear}))
    }, [selectedMonth, selectedYear])
    useEffect(() => {
        switch (showcaseType) {
            case 'classic':
                setEventType(EVENT_TYPE.Classic)
                break
            case 'lazy':
                setEventType(EVENT_TYPE.Lazy)
                break
            default:
                setEventType(EVENT_TYPE.All)
        }
    }, [showcaseType])

    const eventsList = useMemo(() => {
        if (events === null) {
            return <LoadingIcon/>
        }

        let filtered: DBEventWithOrganizer[] = []
        for (let event of events) {
            if (event.organizer === walletAddress &&
                (event.showcaseType === EVENT_TYPE[eventType] || eventType === EVENT_TYPE.All)
            ) {
                filtered.push(event)
            }
        }
        if (filtered.length === 0) {
            return <AlertElement centered>{t('status.noEvents')}</AlertElement>
        }

        return filtered.map((item) => (
            <EventCardWithLink event={item} link={`/event/${item.organizerUrl}/${item.url}`} key={item.id}/>
        ))
    }, [events, eventType, walletAddress])

    if (!showcaseType) {
        return null
    }

    const addEventHandler = () => {
        dispatch(setModalCreateEvent(true))
    }

    return <div className="page-content">
        <div className="container">
            <h1>{t(`header.${showcaseType}Events`)}</h1>
            <div className="row gx-2 gx-md-3 mb-3">
                <div className="col-md-auto me-md-0 ms-md-auto mb-4 mb-md-0 pb-4 pb-md-0 mt-n3 mt-md-0 order-md-4">
                    <ButtonElement small additionalClass={'w-100'} onClick={addEventHandler}>
                        {t('button.addEvent')}
                    </ButtonElement>
                </div>
                <div className="col-auto mb-2 mb-xl-3">
                    <DropdownSelector
                        currentItem={selectedYear}
                        list={yearsArray}
                        setItem={setSelectedYear}
                        button
                    />
                </div>
                <div className="col-auto mb-2 mb-xl-3">
                    <DropdownSelector
                        currentItem={selectedMonth}
                        list={getMonthList()}
                        setItem={setSelectedMonth}
                        button
                    />
                </div>
                <div className="col-auto mb-2 mb-xl-3">
                    <DropdownSelector
                        currentItem={eventType}
                        list={getEventTypeList()}
                        setItem={setEventType}
                        button
                    />
                </div>
            </div>
            <div className="row">{eventsList}</div>
        </div>
    </div>
}

export default Events
