import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useConnectWallet} from '@web3-onboard/react'
import {Trans, useTranslation} from 'react-i18next'
import {MintBlock} from './index'
import {
    getTicketBatch,
    getTicketDescription,
    getTicketName,
    getTicketProperties,
    mintNft,
    resetState,
    setMintContract,
    setTicketBatchError,
    setTicketDescriptionError, setTicketNameError, setTicketPropertiesError
} from '../../store/mintSlice'
import {CHAINS} from '../../utils/constants'
import {AppDispatch} from '../../store/store'
import {
    closeAllModals,
    getCurrentNetwork,
    getImageUrl, getLoadedImage,
    getWalletAddress,
    getWeb3, setImageUrlError,
    setModalError, setModalVideo
} from '../../store/appSlice'
import {getSelectedEventName} from '../../store/eventsSlice'
import {getSelectedOrganizerName} from '../../store/organizersSlice'
import {getSelectedTicketLevelName, setTicketLevelId} from '../../store/ticketsSlice'
import {PlayIcon} from '../icons'
import {ButtonElement} from '../elements'

const MintPage = () => {
    const {t} = useTranslation()
    const currentNetwork = useSelector(getCurrentNetwork)
    const eventName = useSelector(getSelectedEventName)
    const imageUrl = useSelector(getImageUrl)
    const loadedImage = useSelector(getLoadedImage)
    const organizerName = useSelector(getSelectedOrganizerName)
    const ticketBatch = useSelector(getTicketBatch)
    const ticketDescription = useSelector(getTicketDescription)
    const ticketLevelName = useSelector(getSelectedTicketLevelName)
    const ticketName = useSelector(getTicketName)
    const ticketProperties = useSelector(getTicketProperties)
    const walletAddress = useSelector(getWalletAddress)
    const web3 = useSelector(getWeb3)

    const [{wallet}] = useConnectWallet()

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        return () => {
            dispatch(closeAllModals())
            dispatch(resetState())
            dispatch(setTicketLevelId(null))
        }
    }, [walletAddress])
    useEffect(() => {
        if (web3 && currentNetwork) {
            dispatch(setMintContract(new web3.eth.Contract(CHAINS[currentNetwork].nftMinterContract721Abi, CHAINS[currentNetwork].nftMinterContract721)))
        } else {
            dispatch(setMintContract(null))
        }
    }, [currentNetwork])

    const submitHandler = () => {
        let error = false
        if (imageUrl.value === null) {
            error = true
            dispatch(setImageUrlError(t('error.chooseImage')))
        } else {
            dispatch(setImageUrlError(null))
        }

        if (organizerName === '' || eventName === '' || ticketLevelName === '') {
            error = true
        }

        if (!ticketBatch.value) {
            error = true
            dispatch(setTicketBatchError(t('error.mustEnter', {name: t('form.label.batchAmount')})))
        } else if (ticketBatch.value > 100) {
            error = true
            dispatch(setTicketBatchError(t('error.mustBeLessOrEqual', {name: t('form.label.batchAmount'), value: 100})))
        } else {
            dispatch(setTicketBatchError(null))
        }

        if (ticketDescription.value === '') {
            error = true
            dispatch(setTicketDescriptionError(t('error.mustEnter', {name: t('form.label.description')})))
        } else if (ticketDescription.value.length < 3) {
            error = true
            dispatch(setTicketDescriptionError(t('error.mustBeGreater', {name: t('form.label.description'), value: 2})))
        } else {
            dispatch(setTicketDescriptionError(null))
        }

        if (ticketName.value === '') {
            error = true
            dispatch(setTicketNameError(t('error.mustEnter', {name: t('form.label.ticketName')})))
        } else {
            dispatch(setTicketNameError(null))
        }

        let haveError = false
        for (let item of ticketProperties.value) {
            if (item.trait_type === '' || item.value === '') {
                error = true
                dispatch(setTicketPropertiesError(t('error.enterAllProperties')))
                haveError = true
                break
            }
        }
        if (!haveError) {
            dispatch(setTicketPropertiesError(null))
        }

        if (!wallet) {
            dispatch(setModalError({text: t('error.walletNotConnected'), buttons: ['close']}))
            return
        }

        if (error) {
            return
        }

        if (loadedImage) {
            dispatch(mintNft({provider: wallet.provider}))
        } else {
            dispatch(setModalError({text: t('error.imageLoadingError'), buttons: ['close']}))
        }
    }
    const tutorialHandler = () => {
        dispatch(setModalVideo({
            link: 'https://www.youtube.com/embed/fkO68Cs__tI?si=z3gpDMidQQUifQBP',
            title: t('tutorial.title.mint')
        }))
    }

    return <div className="page-content">
        <div className="container">
            <div className="row mb-3">
                <div className="col">
                    <h1>{t('header.mintTickets')}</h1>
                </div>
                <div className="col-auto">
                    <ButtonElement link additionalClass={'mt-n2 mt-xl-n1'} onClick={tutorialHandler}>
                        <span className="me-2"><PlayIcon/></span>
                        <span className="d-md-none">{t('word.tutorial')}</span>
                        <span className="d-none d-md-inline">{t('word.videoTutorial')}</span>
                    </ButtonElement>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-lg-8">
                    <p>
                        <Trans i18nKey={'mint.mintPageDescription'} components={[<a href="https://youtu.be/fkO68Cs__tI?si=G2I8O-lq-Km0e1KA" target="_blank"/>]}/>
                    </p>
                </div>
            </div>
            <MintBlock maxBatchAmount={100} onSubmit={submitHandler}/>
        </div>
    </div>
}

export default MintPage
