import React from 'react'
import {IconProps} from '../../store/types'

const MyShChIcon = (props: IconProps) => {
    return <svg className={props.className} width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16.5 1.5A14.89 14.89 0 0 0 5.88 5.89a14.83 14.83 0 0 0-4.39 10.6c0 2.03.4 4 1.16 5.86v.01l.11.26H6.3a11.9 11.9 0 0 1 10.2-18h9.16A15.02 15.02 0 0 0 16.5 1.5Zm-6.23.26A15.89 15.89 0 0 1 16.49.5a15.83 15.83 0 0 1 10.86 4.25l.93.86H16.5a10.9 10.9 0 0 0-8.86 17.21l.56.8H2.13l-.13-.3a9.7 9.7 0 0 1-.26-.6v.01l.46-.2-.47.19a16.24 16.24 0 0 1 .03-12.46 16.01 16.01 0 0 1 8.5-8.5ZM30.85 9.4l.14.26a5.27 5.27 0 0 1 .27.6c.5 1.2.85 2.42 1.06 3.69a15.84 15.84 0 0 1-4.47 13.86 16.01 16.01 0 0 1-22.17.44l-.93-.86h11.79A10.9 10.9 0 0 0 27.26 14.5h-10.2v.02h-.5a2 2 0 0 0-.02 3.99h6.87l-.28.69a7.04 7.04 0 0 1-6.59 4.4 7.08 7.08 0 0 1-7.1-7.09 7.08 7.08 0 0 1 7.1-7.1m0 0 14.3-.02Zm-.49 4.15v-.06h12.02l.09.4a11.9 11.9 0 0 1-11.62 14.48H7.38a14.83 14.83 0 0 0 9.16 3.11 14.89 14.89 0 0 0 10.6-4.39 14.83 14.83 0 0 0 4.2-13 15.35 15.35 0 0 0-1.05-3.57l-.06-.14-13.7.02a6.08 6.08 0 0 0-4.32 10.41 6.08 6.08 0 0 0 4.33 1.79 6.04 6.04 0 0 0 5.33-3.1h-5.33a2.99 2.99 0 0 1-.49-5.95Z"
              fill="#7FC7FF"></path>
    </svg>
}

export default MyShChIcon
