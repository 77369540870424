import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {setModalAddProperties} from '../../store/appSlice'
import {getTicketProperties, setTicketProperties, setTicketPropertiesError} from '../../store/mintSlice'
import {ButtonElement} from '../elements'
import {CloseIcon} from '../icons'

const PropertiesBlock = () => {
    const {t} = useTranslation()
    const ticketProperties = useSelector(getTicketProperties)

    const dispatch = useDispatch()

    const deleteNftProperties = (index: number) => {
        let newState = [...ticketProperties.value]
        newState.splice(index, 1)
        dispatch(setTicketProperties(newState))
        if (ticketProperties.error.status) {
            let error = false
            for (let item of newState) {
                if (item.trait_type === '' || item.value === '') {
                    error = true
                    break
                }
            }
            if (!error) {
                dispatch(setTicketPropertiesError(null))
            }
        }
    }

    return <div className="mb-6">
        <div className="mb-3">
            <ButtonElement
                small
                outline
                onClick={() => {
                    dispatch(setModalAddProperties(true))
                }}
            >{ticketProperties.value.length > 0 ? t('button.editProperties') : t('button.addProperties')}</ButtonElement>
        </div>
        {ticketProperties.error.status ?
            <div className="alert-danger mb-3">{ticketProperties.error.text}</div>
            :
            null
        }
        {ticketProperties.value.length ?
            <div className="row">
                <div className="col-xl-8">
                    <div className="row-table">
                        {ticketProperties.value.map((data, index) => {
                            const {trait_type, value} = data
                            return <div className="row-table__item mb-2" key={index}>
                                <div className="row align-items-center">
                                    <div className="mb-2 col-md-4"><b>{trait_type}</b></div>
                                    <div className="mb-2 col-md-7">{value}</div>
                                    <button
                                        className="btn p-2 btn-with-opacity btn-item-delete"
                                        onClick={() => {
                                            deleteNftProperties(index)
                                        }}
                                    >
                                        <CloseIcon/>
                                    </button>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
            :
            null
        }
    </div>
}

export default PropertiesBlock
