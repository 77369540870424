import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getModalCreateShowcaseV2, getWalletAddress, setModalCreateShowcaseV2, setModalError} from '../../store/appSlice'
import {ButtonElement, DateElement, IDropdownItem, InputElement} from '../elements'
import {AppDispatch} from '../../store/store'
import {checkUrlString} from '../../utils/functions'
import {CloseIcon} from '../icons'
import {checkShowcaseName, setShowcaseParams} from '../../store/showcaseV2Slice'
import {SHOWCASE_TYPES} from '../../utils/constants'
import {TShowcaseType} from '../../store/types'

interface propsType {
    show: boolean
}

const typeList: IDropdownItem[] = []
for (let key in SHOWCASE_TYPES) {
    typeList.push({id: key, name: SHOWCASE_TYPES[key as TShowcaseType]})
}

const ModalCreateShowcaseV2 = (props: propsType) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const [enableDate, setEnableDate] = useState<Date | null>(new Date(Date.now() + 3600000))
    const [enableError, setEnableError] = useState('')
    const [disableDate, setDisableDate] = useState<Date | null>(new Date(Date.now() + 7200000))
    const [disableError, setDisableError] = useState('')
    const [showcaseName, setShowcaseName] = useState('')
    const [showcaseNameChecking, setShowcaseNameChecking] = useState(false)
    const [showcaseNameEdited, setShowcaseNameEdited] = useState(false)
    const [showcaseNameError, setShowcaseNameError] = useState('')
    const modal = useSelector(getModalCreateShowcaseV2)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (!showcaseNameEdited) {
            setShowcaseNameEdited(true)
            return
        }

        const name = showcaseName.trim()
        if (name === '') {
            setShowcaseNameError(t('error.mustEnter', {name: t('form.label.name')}))
        } else if (!checkUrlString(name)) {
            setShowcaseNameError(`${t('error.wrong', {name: t('form.label.name')})} (a-Z, 0-9, "-", ".", "_", "~")`)
        } else {
            setShowcaseNameError('')
            setShowcaseNameChecking(true)
            const onSuccess = () => {
                setShowcaseNameError('')
                setShowcaseNameChecking(false)
            }
            const onFailure = () => {
                setShowcaseNameError(t('error.alreadyExist', {name: t('form.label.name')}))
                setShowcaseNameChecking(false)
            }
            dispatch(checkShowcaseName({name, onSuccess, onFailure}))
        }
    }, [showcaseName])

    const closeModal = () => {
        dispatch(setModalCreateShowcaseV2(null))
    }
    const createShowcaseHandler = () => {
        if (!modal || !SHOWCASE_TYPES[modal.type]) {
            dispatch(setModalError({text: t('error.wrong', {name: t('form.label.showcaseType')})}))
            return
        }

        let error = false
        const enableTimestamp = enableDate ? Math.floor(enableDate.getTime() / 1000) : 0
        const disableTimestamp = disableDate ? Math.floor(disableDate.getTime() / 1000) : 0
        const name = showcaseName.trim()

        if (showcaseNameError !== '' || showcaseNameChecking) {
            error = true
        } else if (name === '') {
            error = true
            setShowcaseNameError(t('error.mustEnter', {name: t('form.label.name')}))
        }
        if (enableTimestamp <= 0) {
            error = true
            setEnableError(t('error.wrong', {name: t('form.label.enableDate')}))
        } else {
            setEnableError('')
        }
        if (disableTimestamp <= 0) {
            error = true
            setDisableError(t('error.wrong', {name: t('form.label.disableDate')}))
        } else if (disableTimestamp <= enableTimestamp) {
            error = true
            setDisableError(t('error.mustBeGreater', {name: t('form.label.disableDate'), value: t('form.label.enableDate')}))
        } else {
            setDisableError('')
        }

        if (error || !walletAddress) {
            return
        }
        dispatch(setShowcaseParams({
            showcaseName: name,
            beneficiary: walletAddress,
            enableAfter: enableTimestamp,
            disableAfter: disableTimestamp,
            type: modal.type,
        }))
    }

    if (!props.show || !modal) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Create a new showcase"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">{t(`modal.title.newShowcaseV2${modal.type}`)}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body pt-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-4">
                                <InputElement
                                    errorText={showcaseNameError}
                                    label={<>{t('form.label.name')} <span
                                        className="text-muted">(a-Z, 0-9, "-", ".", "_", "~")</span></>}
                                    value={showcaseName}
                                    onChange={(value) => {
                                        if (checkUrlString(value)) {
                                            setShowcaseName(value)
                                            setShowcaseNameError('')
                                        } else {
                                            setShowcaseNameError(`${t('error.wrong', {name: t('form.label.name')})} (a-Z, 0-9, "-", ".", "_", "~")`)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <div className="mb-4">
                                <DateElement
                                    value={enableDate}
                                    onChange={setEnableDate}
                                    label={t('form.label.enableDate')}
                                    errorText={enableError}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="mb-4">
                                <DateElement
                                    value={disableDate}
                                    onChange={setDisableDate}
                                    label={t('form.label.disableDate')}
                                    errorText={disableError}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer justify-content-end">
                    <ButtonElement
                        additionalClass={'flex-grow-1 flex-lg-grow-0'}
                        onClick={createShowcaseHandler}
                        disabled={showcaseNameChecking}
                    >{t('button.create')}</ButtonElement>
                </div>
            </div>
        </div>
    </div>
}

export default ModalCreateShowcaseV2
