import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {
    getEventsByOrganizer, getSelectedEventId, getSelectedEventName,
    requestEventsByOrganizer, putEventTitle, setEventsByOrganizer,
    setSelectedEventId
} from '../../store/eventsSlice'
import {getSelectedOrganizerName} from '../../store/organizersSlice'
import {ButtonElement, DropdownPlaceholder, DropdownSelector, IDropdownItem} from '../elements'
import {sendRequestWithAuth, setModalCreateEvent, setModalEditString} from '../../store/appSlice'
import {EditIcon} from '../icons'

interface propsType {
    error?: string
}

const SelectEvent = (props: propsType) => {
    const {t} = useTranslation()
    const events = useSelector(getEventsByOrganizer)
    const organizerName = useSelector(getSelectedOrganizerName)
    const selectedEvent = useSelector(getSelectedEventId)
    const eventName = useSelector(getSelectedEventName)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (organizerName !== '' && !events) {
            dispatch(sendRequestWithAuth(requestEventsByOrganizer()))
        } else if (organizerName === '') {
            dispatch(setEventsByOrganizer(null))
        }
        if (events && events.length > 0) {
            let found = false
            if (selectedEvent !== null) {
                for (let item of events) {
                    if (item.id === selectedEvent) {
                        found = true
                        break
                    }
                }
            }
            if (!found) {
                dispatch(setSelectedEventId(events[0].id))
            }
        }
    }, [events, organizerName])

    const eventsList: IDropdownItem[] = events?.map((item): IDropdownItem => {
        return {id: item.id, name: item.name}
    }) || []
    const editHandler = () => {
        dispatch(setModalEditString({
            title: t('modal.title.edit', {name: t('form.label.eventTitle')}),
            label: t('form.label.eventTitle'),
            text: eventName,
            handler: (text: string) => {
                return sendRequestWithAuth(putEventTitle(text))
            },
        }))
    }

    return <>
        <div className="d-flex align-items-center justify-content-between">
            <label className="form-label">{t('form.label.eventTitle')} <span className="text-danger">*</span></label>
            {eventName !== '' ?
                <ButtonElement
                    small
                    outline
                    type={'secondary'}
                    additionalClass={'border-0 p-1 mb-1 mt-n2'}
                    onClick={editHandler}
                >
                    <EditIcon/>
                </ButtonElement>
                :
                null
            }
        </div>
        {events ?
            <DropdownSelector
                list={eventsList}
                setItem={(id) => {
                    dispatch(setSelectedEventId(id))
                }}
                currentItem={selectedEvent}
                firstItem={{id: 'firstItem', name: t('button.addEvent')}}
                firstItemAction={() => {
                    dispatch(setModalCreateEvent(true))
                }}
                errorText={props.error}
            />
            :
            <DropdownPlaceholder title={organizerName === '' ? t('status.selectOrganizer') : `${t('status.loading')}...`}/>
        }
    </>
}

export default SelectEvent
