import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getModalError, setModalError} from '../../store/appSlice'
import {ChainSelector} from '../wallet'
import {ExternalLinkIcon} from '../icons'
import {ButtonElement} from '../elements'

interface propsType {
    show: boolean
}

const ModalError = (props: propsType) => {
    const {t} = useTranslation()
    const error = useSelector(getModalError)

    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch(setModalError(null))
    }

    if (!error || !props.show) {
        return null
    }

    return <div
        className={`modal fade show`}
        id="stepProgressModal"
        tabIndex={-1}
        aria-labelledby="Error"
        aria-hidden="true"
        style={{display: 'block'}}
    >
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header pb-2">
                    <h2 className="modal-title text-error">{error.title || t('modal.title.error')}</h2>
                </div>
                {error.links ?
                    <div className="modal-body text-break">
                        {error.links?.map((item) => {
                            return <a
                                key={item.url}
                                href={item.url}
                                target={item.targetBlank ? '_blank' : ''}
                                rel={item.targetBlank ? 'noopener noreferrer' : ''}
                            >
                                {item.title || <>{t('word.transaction')}<ExternalLinkIcon/></>}
                            </a>
                        })}
                    </div>
                    :
                    null
                }
                <div className="modal-body text-break">
                    {error.text instanceof Array ? error.text.map((item, index) => (
                            <p key={index}>{item}</p>
                        ))
                        :
                        <p>{error.text}</p>
                    }
                </div>
                {error.buttons ?
                    <div className="modal-footer justify-content-end border-top-0 pt-0">
                        {error.buttons.map((item, idx) => {
                            switch (item) {
                                case 'close':
                                    return <ButtonElement
                                        key={idx}
                                        outline
                                        onClick={closeModal}
                                    >{t('button.close')}</ButtonElement>
                                case 'chooseNetwork':
                                    return <ChainSelector key={idx}/>
                                default:
                                    return null
                            }
                        })}
                    </div>
                    :
                    null
                }
            </div>
        </div>
    </div>
}

export default ModalError
