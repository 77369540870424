import {createContext} from 'react'
import {TShowcaseType} from '../store/types'

interface IThemeContext {
    dark: boolean
    toggleDark: () => void
    showcaseType: TShowcaseType | null
    changeShowcaseType: (val: TShowcaseType | null) => void
}

const initialState: IThemeContext = {
    dark: true,
    toggleDark: () => {},
    showcaseType: null,
    changeShowcaseType: () => {}
}

const ThemeContext = createContext<IThemeContext>(initialState)

export default ThemeContext
