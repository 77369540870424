import React, {useState} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {useTranslation} from 'react-i18next'
import {CopyIcon} from '../icons'
import {ButtonElement, InputElement} from './index'
import {COPY_HINT_TIME} from '../../utils/constants'

interface propsType {
    text: string | number
}

const CopyToClipboardInput = (props: propsType) => {
    const {t} = useTranslation()
    const [copiedHint, setCopiedHint] = useState(false)

    return <div className="input-group mb-3">
        <InputElement onChange={() => {}} value={props.text.toString()} readonly={true}/>
        <CopyToClipboard
            text={props.text.toString()}
            onCopy={() => {
                setCopiedHint(true)
                setTimeout(() => {
                    setCopiedHint(false)
                }, COPY_HINT_TIME)
            }}
        >
            <ButtonElement small outline type={'secondary'}>
                <CopyIcon/>
                <i className="btn-hint" style={{display: copiedHint ? 'block' : 'none'}}>{t('element.status.copied')}</i>
            </ButtonElement>
        </CopyToClipboard>
    </div>
}

export default CopyToClipboardInput
