import React from 'react'

interface IProps {
    title: string
}

const DropdownPlaceholder = (props: IProps) => {
    return <div className="form-control disabled">
        <div className="value placeholder">{props.title}</div>
    </div>
}

export default DropdownPlaceholder
