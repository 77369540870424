import React, {ReactNode, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useSearchParams} from 'react-router-dom'
import {ethers} from 'ethers'
import {useTranslation} from 'react-i18next'
import {AlertElement, CopyToClipboardBlock, DropdownSelector, IDropdownItem, InputElement} from '../elements'
import {CHAINS} from '../../utils/constants'
import {checkTicket, getCheckedTicketEvents, setCheckedTicketEvents} from '../../store/ticketsSlice'
import {AppDispatch} from '../../store/store'

const CheckTickets = () => {
    const {t} = useTranslation()
    const [contractError, setContractError] = useState('')
    const [searchParams, setSearchParams] = useSearchParams('')
    const network = searchParams.get('network') || ''
    const contract = searchParams.get('contract') || ''
    const tokenId = searchParams.get('tokenId') || ''
    const checkedTicketEvents = useSelector(getCheckedTicketEvents)

    let networkList: IDropdownItem[] = []
    for (let key in CHAINS) {
        networkList.push({id: key, name: CHAINS[key].label})
    }
    const error = network === '' || contract === '' || tokenId === ''

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(setCheckedTicketEvents(null))
        if (!error) {
            dispatch(checkTicket({network, contract, tokenId}))
        }
    }, [network, contract, tokenId])

    const getTicketStatus = (): ReactNode => {
        if (error) {
            return <AlertElement centered>{t('error.enterTicketData')}</AlertElement>
        }
        if (checkedTicketEvents) {
            if (checkedTicketEvents.length === 0) {
                return <AlertElement centered type={'danger'}>{t('error.ticketNotFound')}</AlertElement>
            } else {
                return checkedTicketEvents.map((item, index) => {
                    return <AlertElement centered type={item.isUsed ? 'warning' : 'success'} key={index}>
                        {item.isUsed ? t('status.ticketUsed') : t('status.ticketFor')}
                        <Link
                            to={`/event/${item.organizerUrl}/${item.eventUrl}`}
                            className={'link'}
                        >{item.eventName}</Link>
                    </AlertElement>
                })
            }
        } else {
            return <AlertElement centered type={'secondary'}>{t('status.checking')}...</AlertElement>
        }
    }

    return <div className="page-content">
        <div className="container">
            <div className="row">
                <div className="col">
                    <h1>{t('header.checkTicket')}</h1>
                </div>
                <div className="col-auto">
                    <CopyToClipboardBlock
                        buttonText={t('button.copyLink')}
                        text={window.location.href}
                    />
                </div>
            </div>
            <div className="mb-2">
                <form>
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="mb-4">
                                <InputElement
                                    errorText={contractError}
                                    label={t('form.label.contract')}
                                    value={contract}
                                    placeholder={'0x000'}
                                    onChange={(value) => {
                                        if (ethers.utils.isAddress(value)) {
                                            searchParams.set('contract', value)
                                            setSearchParams(searchParams)
                                            setContractError('')
                                        } else {
                                            setContractError(t('error.pasteRightAddress'))
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mb-4">
                                <InputElement
                                    value={tokenId}
                                    label={t('form.label.tokenId')}
                                    placeholder={'000'}
                                    onChange={(value) => {
                                        if (value === '') {
                                            searchParams.set('tokenId', value)
                                            setSearchParams(searchParams)
                                            return
                                        }
                                        const id = Number(value)
                                        if (!isNaN(id)) {
                                            searchParams.set('tokenId', Math.floor(id).toString())
                                            setSearchParams(searchParams)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="mb-4">
                                <DropdownSelector
                                    currentItem={network}
                                    list={networkList}
                                    setItem={(value) => {
                                        searchParams.set('network', value)
                                        setSearchParams(searchParams)
                                    }}
                                    title={t('form.label.selectNetwork')}
                                    label={t('form.label.network')}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            {getTicketStatus()}
        </div>
    </div>
}

export default CheckTickets
