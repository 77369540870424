import React, {ReactNode} from 'react'
import DatePicker from 'react-datepicker'
import {LabelElement} from './index'

interface propsType {
    additionalClass?: string
    error?: boolean
    errorText?: string
    label?: ReactNode
    onChange: (value: Date | null) => void
    placeholder?: string
    readonly?: boolean
    setError?: (text: string) => void
    value: Date | null
}

const DateElement = (props: propsType) => {
    const error = props.errorText && props.errorText !== '' ? true : props.error

    return <>
        {props.label ? <LabelElement>{props.label}</LabelElement> : null}
        <div className={error ? 'is-invalid' : ''}>
            <DatePicker
                selected={props.value}
                onChange={props.onChange}
                isClearable
                placeholderText={props.placeholder}
                className={`form-control ${error ? 'is-invalid' : ''} ${props.additionalClass || ''}`}
                dateFormat="yyyy/MM/dd HH:mm"
                showTimeSelect
            />
        </div>
        {props.errorText && props.errorText !== '' ?
            <div className="invalid-feedback">{props.errorText}</div>
            :
            null
        }
    </>
}

export default DateElement
