import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import i18next from 'i18next'
import {RootState} from './store'
import {API_URL} from '../utils/constants'
import {
    checkResponse,
    setModalAddEventToShowcase,
    setModalAddShowcaseToEvent, setModalCreateEvent,
    setModalEditEvent, setModalEditString,
    setRedirectPath
} from './appSlice'
import {
    DBEvent,
    DBEventWithOrganizer, DBShowcase,
    DBThirdpartyEvent, IEventByDay,
    IEventByOrganizer, IEventFilters,
    IEventsObject, IShowcaseToEvent, IThirdpartyEvent,
    SliceResponse
} from './types'
import {setToWalletStorage} from './storage'
import {setShowcaseEvents} from './launchpadSlice'
import {setShowcaseEvents as setLazyShowcaseEvents} from './lazyMintingSlice'
import {setShowcaseEvents as setShowcaseV2Events} from './showcaseV2Slice'
import {postTicketLevel} from './ticketsSlice'

interface EventsState {
    currentEventId: number | null
    events: DBEventWithOrganizer[] | null
    eventsByOrganizer: IEventByOrganizer[] | null
    eventsObject: IEventsObject //Object with events from different organizers
    selectedEventId: number | null
    thirdpartyEvents: DBThirdpartyEvent[] | null
}

const initialState: EventsState = {
    currentEventId: null,
    events: null,
    eventsByOrganizer: null,
    eventsObject: {},
    selectedEventId: null,
    thirdpartyEvents: null,
}

export const addEventToShowcase = createAsyncThunk(
    'events/addEventToShowcase',
    async (linkTitle: string, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {modalAddEventToShowcase} = state.app
        const {jwt} = state.auth
        const {selectedEventId} = state.events

        let response: SliceResponse = {}
        if (!jwt || !selectedEventId || !modalAddEventToShowcase) {
            response.error = {text: i18next.t('error.jwtOrEventNotFound')}
        } else {
            const {contract, network, showcaseName} = modalAddEventToShowcase
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const body = {contract, name: showcaseName, network: Number(network), linkTitle}
                const result = await axios.post(`${API_URL}events/${selectedEventId}/showcases`, body, config)
                response.status = result.status
                response.data = null
                response.successCallback = () => {
                    dispatch(setModalAddEventToShowcase(null))
                    dispatch(setShowcaseEvents(null))
                    dispatch(setLazyShowcaseEvents(null))
                    dispatch(setShowcaseV2Events(null))
                }
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        dispatch(checkResponse(response))
    }
)

export const addShowcaseToEvent = createAsyncThunk(
    'events/addShowcaseToEvent',
    async (params: IShowcaseToEvent, {getState, dispatch}): Promise<void> => {
        const {contract, name, linkTitle, network} = params
        const state = getState() as RootState
        const {jwt} = state.auth
        const {currentEventId} = state.events

        let response: SliceResponse = {}
        if (!jwt || !currentEventId) {
            response.error = {text: i18next.t('error.jwtOrEventNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const body = {contract, name, network: Number(network), linkTitle}
                const result = await axios.post(`${API_URL}events/${currentEventId}/showcases`, body, config)
                response.status = result.status
                response.data = null
                response.successCallback = () => {
                    dispatch(setModalAddShowcaseToEvent(false))
                    dispatch(setCurrentEventId(null))
                }
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        dispatch(checkResponse(response))
    }
)

export const addThirdpartyEvent = createAsyncThunk(
    'events/addThirdpartyEvent',
    async (thirdpartyEvent: IThirdpartyEvent, {getState, dispatch}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth

        let response: SliceResponse = {}
        if (!jwt) {
            response.error = {text: i18next.t('error.notAuthorized')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const body = {...thirdpartyEvent}
                const result = await axios.post(`${API_URL}calendar/event`, body, config)
                response.status = result.status
                response.data = null
                response.setData = (value) => {
                    dispatch(setEventsByOrganizer(value))
                }
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.successCallback = () => {
            dispatch(setRedirectPath('/calendar'))
        }
        dispatch(checkResponse(response))
    }
)

export const delShowcaseFromEvent = createAsyncThunk(
    'events/delShowcaseFromEvent',
    async (params: {eventId: number, showcaseId: number}, {getState, dispatch}): Promise<void> => {
        const {eventId, showcaseId} = params
        const state = getState() as RootState
        const {jwt} = state.auth

        let response: SliceResponse = {}
        if (!jwt || isNaN(eventId) || isNaN(showcaseId)) {
            response.error = {text: i18next.t('error.jwtOrEventNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.delete(`${API_URL}events/${eventId}/showcases?id=${showcaseId}`, config)
                response.status = result.status
                response.data = null
                response.successCallback = () => {
                    dispatch(setCurrentEventId(null))
                    dispatch(setShowcaseEvents(null))
                    dispatch(setLazyShowcaseEvents(null))
                    dispatch(setShowcaseV2Events(null))
                }
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        dispatch(checkResponse(response))
    }
)

export const postEvent = createAsyncThunk(
    'events/postEvent',
    async (
        params: {name: string, startTime?: Date, endTime?: Date, description?: string},
        {getState, dispatch}
    ): Promise<void> => {
        let {name, startTime, endTime, description} = params
        const state = getState() as RootState
        const {jwt} = state.auth
        const {selectedOrganizerId} = state.organizers

        let response: SliceResponse = {}
        if (!jwt || !selectedOrganizerId) {
            response.error = {text: i18next.t('error.jwtUserOrOrganizerNotFound')}
        } else {
            try {
                if (!startTime) {
                    startTime = new Date()
                }
                if (!endTime) {
                    endTime = new Date(Date.now() + 60000)
                }
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const body = {name, startTime: startTime.getTime(), endTime: endTime.getTime(), description}
                const result = await axios.post(`${API_URL}events/${selectedOrganizerId}`, body, config)
                response.status = result.status
                response.data = result.data.event
                response.setData = (value) => {
                    dispatch(setEvents(null))
                    dispatch(setEventsByOrganizer(null))
                    dispatch(setSelectedEventId(value.id))
                    dispatch(setModalCreateEvent(false))
                    dispatch(postTicketLevel({level: 0, title: 'Zero'}))
                }
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        dispatch(checkResponse(response))
    }
)

export const putEvent = createAsyncThunk(
    'events/putEvent',
    async (params: DBEvent, {dispatch, getState}): Promise<void> => {
        const {id, title, url, description, startTime, endTime} = params
        const state = getState() as RootState
        const {jwt} = state.auth

        let response: SliceResponse = {}
        if (!jwt) {
            response.error = {text: i18next.t('error.notAuthorized')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const body = {title, url, description, startTime, endTime}
                const result = await axios.put(`${API_URL}events/${id}`, body, config)
                response.status = result.status
                response.data = null
                response.successCallback = () => {
                    dispatch(setModalEditEvent(false))
                    dispatch(setCurrentEventId(null))
                }
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        dispatch(checkResponse(response))
    }
)

export const putEventTitle = createAsyncThunk(
    'events/putEventTitle',
    async (title: string, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        const {selectedEventId} = state.events

        let response: SliceResponse = {}
        if (!jwt) {
            response.error = {text: i18next.t('error.notAuthorized')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const body = {title}
                const result = await axios.put(`${API_URL}events/${selectedEventId}/title`, body, config)
                response.status = result.status
                response.data = null
                response.successCallback = () => {
                    dispatch(setModalEditString(null))
                    dispatch(setEventsByOrganizer(null))
                }
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        dispatch(checkResponse(response))
    }
)

export const requestCurrentEvent = createAsyncThunk(
    'events/requestCurrentEvent',
    async ({organizerUrl, eventUrl}: { organizerUrl: string, eventUrl: string }, {dispatch}): Promise<void> => {
        let response: SliceResponse = {}
        if (!organizerUrl || !eventUrl) {
            response.error = {text: i18next.t('error.organizerOrEventNotFound')}
        } else {
            try {
                const result = await axios.get(`${API_URL}events/url/${organizerUrl}/${eventUrl}`)
                let event: DBEventWithOrganizer | null = null
                if (result.data) {
                    event = {
                        ...result.data.event,
                        organizer: result.data.event.organizer.toLowerCase(),
                        startTime: new Date(result.data.event.startTime),
                        endTime: new Date(result.data.event.endTime),
                        showcases: result.data.event.showcases.map((item: any): DBShowcase => ({
                            id: item.id,
                            chain: Number(item.chain_id),
                            contract: item.contract.toLowerCase(),
                            name: item.name,
                            eventId: Number(item.event_id),
                            title: item.title,
                        }))
                    }
                }
                response.status = result.status
                response.data = event
            } catch (e: any) {
                response.defaultData = null
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setCurrentEventId(value?.id))
            dispatch(setEventToObject(value))
        }
        dispatch(checkResponse(response))
    }
)

export const requestEvent = createAsyncThunk(
    'events/requestEvent',
    async (eventId: number, {dispatch}): Promise<void> => {
        let response: SliceResponse = {}
        if (isNaN(eventId)) {
            response.error = {text: i18next.t('error.wrongEventId')}
        } else {
            try {
                const result = await axios.get(`${API_URL}events/${eventId}`)
                let event: DBEventWithOrganizer | undefined = {
                    ...result.data.event,
                    startTime: new Date(result.data.event.startTime),
                    endTime: new Date(result.data.event.endTime),
                } || undefined
                response.status = result.status
                response.data = event
            } catch (e: any) {
                response.defaultData = undefined
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setEventToObject(value))
        }
        dispatch(checkResponse(response))
    }
)

export const requestEventsByOrganizer = createAsyncThunk(
    'events/requestEventsByOrganizer',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        const {selectedOrganizerId} = state.organizers

        let response: SliceResponse = {}
        if (!jwt || !selectedOrganizerId) {
            response.error = {text: i18next.t('error.jwtOrOrganizerNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.get(`${API_URL}events/organizer/${selectedOrganizerId}`, config)
                let events: IEventByOrganizer[] = []
                for (let item of result.data.events) {
                    events.push({name: item.title, id: item.id, status: item.organizer_status})
                }
                response.status = result.status
                response.data = events
            } catch (e: any) {
                response.defaultData = []
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setEventsByOrganizer(value))
        }
        dispatch(checkResponse(response))
    }
)

export const requestEventsWithFilter = createAsyncThunk(
    'events/requestEventsWithFilter',
    async (filter: IEventFilters, {dispatch}): Promise<void> => {
        let response: SliceResponse = {}
        try {
            let queryStr = '?'
            if (filter.month !== undefined) {
                queryStr += `month=${filter.month}`
            }
            if (filter.year) {
                queryStr += `&year=${filter.year}`
            }
            const result = await axios.get(`${API_URL}events${queryStr}`)
            response.status = result.status
            const events: DBEventWithOrganizer[] = []
            for (let event of result.data.events) {
                let types: string[] = []
                for (let item of event.showcases) {
                    if (types.indexOf(item.type) < 0) {
                        types.push(item.type)
                    }
                }
                let showcaseType: string | undefined = undefined
                if (types.length > 0) {
                    showcaseType = types.length > 1 ? 'Mixed' : types[0]
                }
                events.push({
                    ...event,
                    startTime: new Date(event.startTime),
                    endTime: new Date(event.endTime),
                    showcaseType,
                })
            }
            response.data = events
        } catch (e: any) {
            response.defaultData = []
            if (e.response) {
                response.status = e.response.status
                response.error = {text: e.response.data.error}
            } else {
                response.error = {text: e.message}
            }
        }
        response.setData = (value) => {
            dispatch(setEvents(value))
        }
        dispatch(checkResponse(response))
    }
)

export const requestThirdpartyEventsWithFilter = createAsyncThunk(
    'events/requestThirdpartyEventsWithFilter',
    async (filter: IEventFilters, {dispatch}): Promise<void> => {
        let response: SliceResponse = {}
        try {
            let queryStr = '?'
            if (filter.month !== undefined) {
                queryStr += `month=${filter.month}`
            }
            if (filter.year) {
                queryStr += `&year=${filter.year}`
            }
            const result = await axios.get(`${API_URL}calendar/events${queryStr}`)
            const events: DBThirdpartyEvent[] = result.data.events.map((item: DBThirdpartyEvent) => ({
                ...item,
                startTime: new Date(item.startTime),
                endTime: new Date(item.endTime),
            }))
            response.status = result.status
            response.data = events
        } catch (e: any) {
            response.defaultData = []
            if (e.response) {
                response.status = e.response.status
                response.error = {text: e.response.data.error}
            } else {
                response.error = {text: e.message}
            }
        }
        response.setData = (value) => {
            dispatch(setThirdpartyEvents(value))
        }
        dispatch(checkResponse(response))
    }
)

export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        resetEventsState: (state) => {
            let key: keyof EventsState
            for (key in initialState) {
                Reflect.set(state, key, initialState[key])
            }
        },
        setCurrentEventId: (state, action: PayloadAction<number | null>) => {
            state.currentEventId = action.payload
        },
        setEvents: (state, action: PayloadAction<DBEventWithOrganizer[] | null>) => {
            state.events = action.payload
        },
        setEventsByOrganizer: (state, action: PayloadAction<IEventByOrganizer[] | null>) => {
            state.eventsByOrganizer = action.payload
        },
        setEventsObject: (state, action: PayloadAction<IEventsObject>) => {
            state.eventsObject = action.payload
        },
        setEventToObject: (state, action: PayloadAction<DBEventWithOrganizer | null>) => {
            if (action.payload) {
                state.eventsObject[action.payload.id] = action.payload
            }
        },
        setSelectedEventId: (state, action: PayloadAction<number | null>) => {
            state.selectedEventId = action.payload
            setToWalletStorage('eventId', action.payload)
        },
        setThirdpartyEvents: (state, action: PayloadAction<DBThirdpartyEvent[] | null>) => {
            state.thirdpartyEvents = action.payload
        },
    },
})

export const getCurrentEventId = (state: RootState): number | null => state.events.currentEventId
export const getEvent = (eventId: number | null) => (state: RootState): DBEventWithOrganizer | null => {
    if (!eventId || !state.events.eventsObject[eventId]) {
        return null
    }
    return state.events.eventsObject[eventId]
}
export const getEvents = (state: RootState): DBEventWithOrganizer[] | null => state.events.events
export const getEventsByDay = (state: RootState): IEventByDay[] | null => {
    const events: DBEventWithOrganizer[] = state.events.events || []
    const thirdpartyEvents: DBThirdpartyEvent[] = state.events.thirdpartyEvents || []
    let result: IEventByDay[] = []
    let eventIndex = 0
    let thirdpartyIndex = 0
    let currentDay = 0
    let currentMonth = 0
    let currentYear = 0
    let currentEvents: (DBEventWithOrganizer | DBThirdpartyEvent)[] = []
    while (true) {
        if (events.length <= eventIndex && thirdpartyEvents.length <= thirdpartyIndex) {
            break
        }
        let item: DBEventWithOrganizer | DBThirdpartyEvent | null = null
        if (events.length <= eventIndex) {
            item = thirdpartyEvents[thirdpartyIndex]
            thirdpartyIndex++
        } else if (thirdpartyEvents.length <= thirdpartyIndex) {
            item = events[eventIndex]
            eventIndex++
        } else {
            if (events[eventIndex].startTime <= thirdpartyEvents[thirdpartyIndex].startTime) {
                item = events[eventIndex]
                eventIndex++
            } else {
                item = thirdpartyEvents[thirdpartyIndex]
                thirdpartyIndex++
            }
        }
        const day = item.startTime.getDate()
        const month = item.startTime.getMonth()
        const year = item.startTime.getFullYear()
        if (day !== currentDay || month !== currentMonth || year !== currentYear) {
            if (currentEvents.length > 0) {
                result.push({
                    day: currentDay,
                    month: currentMonth,
                    year: currentYear,
                    events: currentEvents,
                })
            }
            currentDay = day
            currentMonth = month
            currentYear = year
            currentEvents = []
        }
        currentEvents.push(item)
    }
    if (currentEvents.length > 0) {
        result.push({
            day: currentDay,
            month: currentMonth,
            year: currentYear,
            events: currentEvents,
        })
    }
    return result
}
export const getEventsByOrganizer = (state: RootState): IEventByOrganizer[] | null => state.events.eventsByOrganizer
export const getEventsObject = (state: RootState): IEventsObject => state.events.eventsObject
export const getSelectedEventId = (state: RootState): number | null => state.events.selectedEventId
export const getSelectedEventName = (state: RootState): string => {
    if (state.events.eventsByOrganizer && state.events.selectedEventId) {
        for (let item of state.events.eventsByOrganizer) {
            if (item.id === state.events.selectedEventId) {
                return item.name
            }
        }
//        state.events.selectedEventId = null
        setToWalletStorage('eventId', null)
    }
    return ''
}
export const getThirdpartyEvents = (state: RootState): DBThirdpartyEvent[] | null => state.events.thirdpartyEvents

export const {
    resetEventsState,
    setCurrentEventId,
    setEvents,
    setEventsByOrganizer,
    setEventsObject,
    setEventToObject,
    setSelectedEventId,
    setThirdpartyEvents
} = eventsSlice.actions

export default eventsSlice.reducer
