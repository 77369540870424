import React, {useContext, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {ThemeContext} from '../../context'

const LayoutLazy = () => {
    const {changeShowcaseType} = useContext(ThemeContext)

    useEffect(() => {
        changeShowcaseType('lazy')
        return () => {
            changeShowcaseType(null)
        }
    }, [])

    return <Outlet/>
}

export default LayoutLazy
